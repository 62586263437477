import React, { Component } from 'react';
import { getSpentOnMe } from '../../../util/APIUtils';
import { Skeleton } from 'antd';
import { getFirstWord } from '../../../util/Helper'

export default class MyTopPurchasingIndividuals extends Component {
	constructor(props) {
		super(props);
		this.state = { data: { datas: [] } };
	}
	componentDidMount = () => {
		const data = [];
		getSpentOnMe(5)
			.then((response) => {
				if(response.length === 0){
					data.push({
						name: 'DataIsempty',
					});
				}else{
					response.map((item, key) => {
						data.push({
							name: item.from.individual.name,
							email: item.from.individual.email,
							amount: item.spentTotal,
						});
						return null;
					});
				}
				const topValues = data.sort((a, b) => b - a).slice(0, 5);
				this.setState({
					data: {
						datas: topValues,
					},
				});
				// console.log(this.state.data.datas.map)
			})
			.catch((err) => {});
	};
	renderTableData() {
		const { data } = this.state;
		if (data.datas.length === 0) {
			return <Skeleton active />;
		} else {
			let total = 0;
			let dataTable
			if(this.state.data.datas[0].name === "DataIsempty"){
				// console.log("empty")
				dataTable = () => {
					return (
						<tr>
							<td>Data is empty</td>
							<td></td>
						</tr>
					);
				}
			}else{
				dataTable = this.state.data.datas.map((item, key) => {
					const { name, email, amount } = item; //destructuring
					total += amount;
					return (
						<tr key={key}>
							<td><a href={"mailto:"+email}>{getFirstWord(name)}</a></td>
							<td>{amount.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
						</tr>
					);
				});
			}
			const value = total.toLocaleString(undefined, { minimumFractionDigits: 0 });
			return (
				<table className="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						{dataTable}
						<tr>
							<td>Total:</td>
							<td>{value}</td>
						</tr>
					</tbody>
				</table>
			);
		}
	}
	render() {
		return (
			<div className="x_panel">
				<h4>My Top Purchasing Individuals</h4>
				<div className="x_title">
					<div className="clearfix"></div>
				</div>
				<div className="x_content table-responsive">{this.renderTableData()}</div>
			</div>
		);
	}
}
