import React, { useContext } from 'react';
import { Context } from '../../../context';
import useWindowDimensions from '../../../util/useWindowDimensions';
import { Link } from 'react-router-dom';

function List(props) {
	const { act } = useWindowDimensions();
	const {onChangeSidebar} = useContext(Context);
	const { link, isActive, icon, name } = props;
	return (
		<li className={isActive ? 'active' : ''}>
			<Link to={link} onClick={ act ? onChangeSidebar : ''}>
				<i className={icon}></i> {name}
			</Link>
		</li>
	);
}

export default List