import React, { Component } from 'react';
import { Skeleton } from 'antd';

export default class StatsComponent extends Component {
	renderComponent = () => {
		if (this.props.percentage === null) {
			return <Skeleton active paragraph={{ rows: 1 }} />;
		} else {
			let percent;
			const { icon, title, data, all } = this.props;
			if (this.props.percentage >= 0) {
				percent = () => {
					return (
						<>
							<i className="green">
								<i className="fa fa-sort-asc"></i>
								{this.props.percentage}%
							</i>{' '}
							From last month
						</>
					);
				};
			} else {
				percent = () => {
					return (
						<>
							<i className="red">
								<i className="fa fa-sort-desc"></i>
								{this.props.percentage}%
							</i>{' '}
							From last month
						</>
					);
				};
			}
			return (
				<>
					<span className="count_top">
						<i className={icon}></i> {title}
					</span>
					<div className="count">{all}</div>
					<span className="count_bottom">{data} In this month</span><br />
					<span className="count_bottom">{percent()}</span>
				</>
			);
		}
	};
	render() {
		return <div className="col-md-2 col-sm-4 col-xs-6 tile_stats_count">{this.renderComponent()}</div>;
	}
}
