import React, { Component } from "react";

export default class PledgeInfo extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <>
        <div>
          <p>Thanks for supporting this worthy cause!</p>
          <p>
            To support this cause, please visit us at the Crowdcycle Kiosk at Jl. Raya Sanggingan 88x, Indomaret Sanggingan in Ubud or at a Crowdcycle Exchange Point near you.
          </p>
          <p>You may also contact us at <a href="mailto:contact@crowdcycle.io">contact@crowdcycle.io</a> or WhatsApp <a href="https://wa.me/62817345516">+62817345516</a>.</p>
          <br/>
          <iframe
            title="map"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3946.0249992470203!2d115.2506517755082!3d-8.49694989154463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd23d3a170eaac7%3A0x15bcde261368514e!2sJl.%20Raya%20Sanggingan%20No.88X%2C%20Kedewatan%2C%20Kecamatan%20Ubud%2C%20Kabupaten%20Gianyar%2C%20Bali%2080571!5e0!3m2!1sid!2sid!4v1698656440789!5m2!1sid!2sid`}
            allowFullScreen
          ></iframe>
          <br/>
          <button type="submit" className="btn btn-primary " onClick={this.props.onShowPledgeInfo}>
            Back
          </button>
        </div>
      </>
    )
  }
}