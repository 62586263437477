import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {
	MonthlyReports,
} from './dashboardComponents';
import StatsLists from './StatsLists'

export default class Reports extends Component {
    render() {
        return (
            <div className="right_col" role="main">
				<StatsLists />
                <div className="row">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<div className="x_panel">
							<div className="x_title">
								<h3>Monthly Reports</h3>
								<ul className="nav navbar-right panel_toolbox">
									<li>
										<Link className="collapse-link">
											<i className="fa fa-chevron-up"></i>
										</Link>
									</li>
								</ul>
								<div className="clearfix"></div>
							</div>
							<MonthlyReports />
						</div>
					</div>
				</div>
            </div>
        )
    }
}
