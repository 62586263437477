import React, { Component } from 'react';
import { transactionOrganization } from '../../../../util/APIUtils';
import { monthArray } from '../../../../util/Helper';

import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import { Button, Icon, Skeleton } from 'antd';

export default class GoalVsContributionBalances extends Component {
	constructor(props) {
		super(props);
		this.state = { data: { datas: [] } };
	}
	componentDidMount = () => {
		const data = [];
		transactionOrganization()
			.then((response) => {
				response.map((item, key) => {
					data.push({
						name: item.organization.organization.organizationName,
						goal: parseInt(item.organization.organization.goal),
						contribute: item.topupFrom,
						balance: item.balance.token,
					});
					return null;
				});

				const topValues = data.sort((a, b) => b - a).slice(0, 5);
				this.setState({
					data: {
						datas: topValues,
					},
				});
			})
			.catch((err) => {});
	};
	renderTableData() {
		const { data } = this.state;
		if (data.datas.length === 0) {
			return <Skeleton active />;
		} else {
			const dataTable = this.state.data.datas.map((item, key) => {
				const { name, goal, contribute, balance } = item; //destructuring
				return (
					<tr key={key}>
						<td>{name}</td>
						<td>{goal.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
						<td>{contribute.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
						<td>{balance.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
					</tr>
				);
			});
			return (
				<table className="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Goal</th>
							<th>Contributions</th>
							<th>Balance</th>
						</tr>
					</thead>
					<tbody>{dataTable}</tbody>
				</table>
			);
		}
	}

	saveAsExcel = async () => {
		const wb = new ExcelJS.Workbook();
		// create new sheet with pageSetup settings for A4 - landscape
		const ws = wb.addWorksheet('sheet', {
			pageSetup: { paperSize: 9, orientation: 'landscape' },
		});

		let datas = [],
			label = ['Name', 'Goal', 'Contribution', 'Balance'];

		let { data } = this.state;

		// adjust pageSetup settings afterwards
		ws.pageSetup.margins = {
			left: 0.7,
			right: 0.7,
			top: 0.75,
			bottom: 0.75,
			header: 0.3,
			footer: 0.3,
		};

		data.datas.map((item, key) => {
			datas.push([item.name, item.goal, item.contribute, item.balance]);
			return null;
		});

		const title = ws.addRow(label);
		title.font = { bold: true };

		datas.map((item, key) => {
			ws.addRow(item);
			return null;
		});

		const nameFile = 'Goal vs Contribution-' + monthArray[new Date().getMonth()] + '.xlsx';
		const buf = await wb.xlsx.writeBuffer();
		saveAs(new Blob([buf]), nameFile);
	};

	render() {
		return (
			<div className="x_panel">
				<div className="row">
					<div className="col-lg-8 col-xs-12">
						<h4>Goal vs Contribution</h4>
					</div>
					<div className="col-lg-4 col-xs-12 text-right">
						<Button type="primary" onClick={() => this.saveAsExcel()} block>
							<Icon type="save" /> Export
						</Button>
					</div>
				</div>
				<div className="x_title">
					<div className="clearfix"></div>
				</div>
				<div className="x_content table-responsive">{this.renderTableData()}</div>
			</div>
		);
	}
}
