import React, { Component } from 'react';
import RecentNotification from '../../../../components/RecentNotification'

export default class RecentNotificationsByBusinesses extends Component {
	render() {
		return (
			<div className="x_panel">
				<div className="x_title">
					<h4>
						Recent Notifications by
						<br />
						Businesses
					</h4>
					<div className="clearfix"></div>
				</div>
				<div className="x_content">
					<div className="dashboard-widget-content">
						<ul className="list-unstyled timeline widget">
							<RecentNotification />
							<RecentNotification />
							<RecentNotification />
							<RecentNotification />
						</ul>
					</div>
				</div>
			</div>
		);
	}
}
