import React, { Component } from 'react';
import { getPledgesByOrganization } from '../../../util/APIUtils';
import { Skeleton } from 'antd';

export default class TopReceivingOrganizations extends Component {
	constructor(props) {
		super(props);
		this.state = { data: { datas: [] } };
	}
	componentDidMount = () => {
		const data = [];
		getPledgesByOrganization()
			.then((response) => {
				response.map((item, key) => {
					let name;
					if (Object.keys(item.organization).length === 0) {
						name = 'Data is deleted';
					} else {
						name = item.organization.organization.organizationName;
					}
					data.push({
						name: name,
						amount: item.pledgeReceived,
					});
					return null;
				});
				const topValues = data.sort((a, b) => b - a).slice(0, 5);
				this.setState({
					data: {
						datas: topValues,
					},
				});
			})
			.catch((err) => {});
	};
	renderTableData() {
		const { data } = this.state;
		if (data.datas.length === 0) {
			return <Skeleton active />;
		} else {
			let total = 0;
			const dataTable = this.state.data.datas.map((item, key) => {
				const { name, amount } = item; //destructuring
				total += amount;
				return (
					<tr key={key}>
						<td>{name}</td>
						<td>{amount.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
					</tr>
				);
			});
			const value = total.toLocaleString(undefined, { minimumFractionDigits: 0 });
			return (
				<table className="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						{dataTable}
						<tr>
							<td>Total:</td>
							<td>{value}</td>
						</tr>
					</tbody>
				</table>
			);
		}
	}
	render() {
		return (
			<div className="x_panel">
				<div className="x_title">
					<h4>Top Receiving<br />Organizations</h4>
					<div className="clearfix"></div>
				</div>
				<div className="x_content table-responsive">{this.renderTableData()}</div>
			</div>
		);
	}
}
