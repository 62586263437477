import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { getTransactionSummaryByUserId } from '../../../util/APIUtils';
import { formatDate, getDay, colorHex } from '../../../util/Helper';

import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import { Button, Icon, Skeleton } from 'antd';

export default class PledgedPurchasedGradient extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			label: [],
			date: null,
			check: null,
			loading: false,
			id: props.user.id
		};
		console.log(props)
	}

	componentDidMount() {
		const date = new Date();
		const start = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
		const end = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
		const user = this.state.id
		this.renderChart(start, end, user)
	}

	renderChart = (start, end, user) => {
		console.log(user)
		const label = [],
			fee = [],
			withdraw = [],
			topup = [],
			spent = [],
			pledge = [],
			transfer = [],
			datasets = [];
		getTransactionSummaryByUserId(start, end, user)
			.then((response) => {
				// console.log(response)
				this.setState({ loading: true })
				response.reverse().map((item, key) => {
					label.push(getDay(item.time));

					item.summary.map((i, k) => {
						if (i.type === 'FEE') {
							fee.push(i.fiatAmount);
						} else if (i.type === 'WITHDRAW') {
							withdraw.push(i.fiatAmount);
						} else if (i.type === 'TOPUP') {
							topup.push(i.amount);
						} else if (i.type === 'SPENT') {
							spent.push(i.amount);
						} else if (i.type === 'TRANSFER') {
							transfer.push(i.amount);
						} else if (i.type === 'PLEDGE') {
							pledge.push(i.amount);
						}
						return null;
					});
					if(fee.length !== key+1){
						fee.push(0)
					}
					if(withdraw.length !== key+1){
						withdraw.push(0)
					}
					if(topup.length !== key+1){
						topup.push(0)
					}
					if(spent.length !== key+1){
						spent.push(0)
					}
					if(transfer.length !== key+1){
						transfer.push(0)
					}
					if(pledge.length !== key+1){
						pledge.push(0)
					}
					return null;
				});
				// let cekFee = fee.filter((data) => data !== 0);
				// let cekWithdraw = withdraw.filter((data) => data !== 0);
				let cekTopup = topup.filter((data) => data !== 0);
				// let cekSpent = spent.filter((data) => data !== 0);
				// let cekTransfer = transfer.filter((data) => data !== 0);
				let cekPledge = pledge.filter((data) => data !== 0);
				
				if (cekPledge.length !== 0) {
					datasets.push({
						label: 'Amount Pledged',
						backgroundColor: colorHex[0],
						data: pledge,
					});
				}
			
				if (cekTopup.length !== 0) {
					datasets.push({
						label: 'Amount Purchased',
						backgroundColor: colorHex[1],
						data: topup,
					});
				}

				

				this.setState({
					label: label,
				});
				// console.log(fee)
				// console.log(withdraw)
				// console.log(topup)
				// console.log(spent)
				// console.log(transfer)
			})
			.catch((err) => {});
		this.setState({
			data: {
				labels: label,
				datasets: datasets,
			},
			loading: false
		});
	}

	componentDidUpdate(prevProps, prevState){
		if(prevState.date !== this.props.date){
			this.setState({ date: this.props.date })
			const start =  this.props.date[0]._d;
			const end = this.props.date[1]._d;
			this.renderChart(start, end)
		}
	}

	setGradientColor = (canvas, color) => {
		const ctx = canvas.getContext('2d');
		const gradient = ctx.createLinearGradient(0, 0, 600, 550);
		gradient.addColorStop(0, color);
		gradient.addColorStop(0.95, 'rgba(133,122,144,0.5)');
		return gradient;
	};

	getChartData = (canvas) => {
		const data = this.state.data;
		if (data.datasets) {
			let colors = colorHex;
			data.datasets.forEach((set, i) => {
				// set.backgroundColor = this.setGradientColor(canvas, colors[i]);
				set.backgroundColor = colors[i];
				set.borderColor = 'white';
				set.borderWidth = 2;
			});
		}
		return data;
	};

	saveAsExcel = async () => {
		const wb = new ExcelJS.Workbook();
		// create new sheet with pageSetup settings for A4 - landscape
		const ws = wb.addWorksheet('sheet', {
			pageSetup: { paperSize: 9, orientation: 'landscape' },
		});

		let datas = [],
			dates = [''];

		let { data } = this.state;

		// adjust pageSetup settings afterwards
		ws.pageSetup.margins = {
			left: 0.7,
			right: 0.7,
			top: 0.75,
			bottom: 0.75,
			header: 0.3,
			footer: 0.3,
		};

		data.labels.map((item, key) => {
			dates.push(item);
			return null;
		});

		data.datasets.map((item, key) => {
			const newData = [];
			newData.push(item.label);
			item.data.map((it, ky) => {
				newData.push(it);
				return null;
			});
			datas.push(newData);
			return null;
		});

		const title = ws.addRow(dates);
		title.font = { bold: true };

		datas.map((item, key) => {
			ws.addRow(item);
			return null;
		});

		// console.log(ws);
		const date = new Date();
		const dateNow = [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)];
		const tanggal = (this.state.date !== null) ? this.state.date : dateNow

		const nameFile = 'PlatformActivity-' + tanggal + '.xlsx';
		const buf = await wb.xlsx.writeBuffer();
		saveAs(new Blob([buf]), nameFile);
	};

	displayChart = () => {
		if(!this.state.loading){
			return(
				<Skeleton active />
			)
		}else{
			return (
				<Line
					options={{
						responsive: true,
					}}
					data={this.getChartData}
				/>
			)
		}
	}

	render() {
		
		return (
			<>
				<div className="row">
					<div className="col-lg-9"></div>
					<div className="col-lg-3 col-xs-12">
						<Button type="primary" onClick={() => this.saveAsExcel()} block>
							<Icon type="save" /> Export
						</Button>
					</div>
				</div>
				{this.displayChart()}
			</>
		);
	}
}
