import React, { Component } from 'react'
import List from './List'

class Business extends Component {
    constructor(props){
        super(props)
        this.state = {
            isActive: false,
        }
    }
    handleActive = (e) => {
        // console.log(this.props.loc)
        if(this.props.loc === e){
            return true
        }else{
            return false
        }
    }
    render() {
        return (
            <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
				<div className="menu_section">
					<h3>General</h3>
					<ul className="nav side-menu">
                        <List isActive={this.handleActive("/")} icon="fa fa-home" link="/" name="Home" />
						<List isActive={this.handleActive("/profile")} icon="fa fa-user" link="/profile" name="Profile" />
                        <List isActive={this.handleActive("/pledge")} icon="fa fa-gift" link="/pledge" name="Pledge" />
                    </ul>
				</div>
			</div>
        )
    }
}

export default Business