import React, { Component } from 'react'

class FinanceDetail extends Component {
    render() {
        return (
            <div className="row">
				<div className="col-md-12 col-sm-12 col-xs-12">
					<div className="x_panel">
						<div className="x_content table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th>Date</th>
										<th>Transaction</th>
										<th>From</th>
										<th>To</th>
										<th>Token Amount</th>
										<th>Cash Amount</th>
									</tr>
								</thead>
								<tbody>
								{this.props.data}
								</tbody>
							</table>
						</div>
					</div>
				</div>
            </div>
        )
    }
}

export default FinanceDetail