import React, { Component } from 'react';
import { Slider, Form, Input, Icon, Tooltip, Upload } from 'antd';
import { Link } from 'react-router-dom';
import { getCurrentUser } from '../../util/APIUtils';

import StatsLists from './StatsLists';
import { Helmet } from 'react-helmet';
// const { TextArea } = Input;

export default class Profile extends Component {
	render() {
		const AntWrappedProfileForm = Form.create()(ProfileForm);
		return (
			<div className="right_col" role="main">
				<Helmet>
					<title>Business</title>
				</Helmet>
				<StatsLists />
				<div className="row">
					<div className="col-md-12 col-sm-12 ">
						<div className="dashboard_graph">
							<div className="row x_title">
								<div className="col-md-6">
									<h3>Profile</h3>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12"></div>
							<div className="col-md-12 col-sm-12">
								<div className="x_panel">
									<div className="x_title">
										<Link to="/edit-profile" className="btn btn-primary">
											Edit
										</Link>
										<div className="clearfix"></div>
									</div>
									<div className="x_content">
										<AntWrappedProfileForm />
									</div>
								</div>
							</div>
							<div className="clearfix"></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class ProfileForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputValue: 0,
			username: '',
			email: '',
			name: '',
			bizName: '',
			description: '',
			address: '',
			phone: '',
			summary: '',
			loading: false,
			avatar: '',
			website: '',
		};
	}

	componentDidMount = () => {
		getCurrentUser()
			.then((response) => {
				console.log(response)
				let image = '';
				if (response.business.avatar === null) {
					image = '';
				} else {
					image = response.business.avatar.url;
				}
				this.setState({
					inputValue: response.business.tokenPercent,
					username: response.username,
					email: response.email,
					name: response.business.name,
					bizName: response.business.businessName,
					description: response.business.description,
					address: response.business.address,
					phone: response.business.phone,
					summary: response.business.summary,
					avatar: image,
					website: response.business.website,
				})
			})
			.catch((err) => {
				console.log(err)
			})
	}

	onChange = (value) => {
		this.setState({
			inputValue: value,
		});
	};

	render() {
		// const { inputValue, username, email, name, bizName, description, address, phone, summary, website } = this.state;
		const { inputValue, username, email, bizName, description, address, phone, website } = this.state;
		
		function formatter(value) {
			return `${value}%`;
		}
		const { getFieldDecorator } = this.props.form;
		const formItemLayout = {
			labelCol: {
				xs: { span: 16 },
				sm: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 16 },
				sm: { span: 8 } /*  */,
			},
		};
		//   const tailFormItemLayout = {
		// 	wrapperCol: {
		// 	  xs: {
		// 		span: 16,
		// 		offset: 0,
		// 	  },
		// 	  sm: {
		// 		span: 16,
		// 		offset: 8,
		// 	  },
		// 	},
		//   };
		const uploadButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		const { avatar } = this.state;
		return (
			<Form {...formItemLayout}>
			<Form.Item label="Avatar">
					{getFieldDecorator('files.avatar', {
						rules: [{ required: true, message: 'Please input your avatar!' }],
					})(
						<Upload
							name="avatar"
							listType="picture-card"
							className="avatar-uploader"
							showUploadList={false}
							action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
							disabled
						>
							{avatar ? <img src={avatar} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
						</Upload>
					)}
				</Form.Item>
				<Form.Item label="Username">
					{getFieldDecorator('username', {
						rules: [{ required: true, message: 'Please input your username!' }],
						initialValue: username
					})(<Input disabled />)}
				</Form.Item>
				<Form.Item label="E-mail">
					{getFieldDecorator('email', {
						rules: [
							{
								type: 'email',
								message: 'The input is not valid E-mail!',
							},
							{
								required: true,
								message: 'Please input your E-mail!',
							},
						],
						initialValue: email
					})(<Input disabled  />)}
				</Form.Item>

				{/* <Form.Item
					label={
						<span>
							Name&nbsp;
							<Tooltip title="What do you want others to call you?">
								<Icon type="question-circle-o" />
							</Tooltip>
						</span>
					}
				>
					{getFieldDecorator('name', {
						rules: [{ required: true, message: 'Please input your name!', whitespace: true }],
						initialValue: name
					})(<Input disabled />)}
				</Form.Item> */}

				<Form.Item
					label={
						<span>
							Business Name&nbsp;
							<Tooltip title="What do you want others to call your business?">
								<Icon type="question-circle-o" />
							</Tooltip>
						</span>
					}
				>
					{getFieldDecorator('businessName', {
						rules: [{ required: true, message: 'Please input your business name!', whitespace: true }],
						initialValue: bizName
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Description">
					{getFieldDecorator('description', {
						rules: [{ required: true, message: 'Please input your description!' }],
						initialValue: description
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Website">
					{getFieldDecorator('website', {
						rules: [{ required: true, message: 'Please input your website!' }],
						initialValue: website,
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Address">
					{getFieldDecorator('address', {
						rules: [{ required: true, message: 'Please input your address!' }],
						initialValue: address
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Phone Number">
					{getFieldDecorator('phone', {
						rules: [{ required: true, message: 'Please input your phone number!' }],
						initialValue: phone
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Token % Accepted">
					{getFieldDecorator('tokenPercent', {
						rules: [{ required: true, message: 'Please choose your token!' }],
						initialValue: inputValue
					})(<Slider tipFormatter={formatter} disabled />)}
				</Form.Item>

				{/* <Form.Item label="Summary">
					{getFieldDecorator('summary', {
						rules: [{ required: true, message: 'Please input your summary!' }],
						initialValue: summary
					})(<TextArea
							placeholder="Autosize height with minimum and maximum number of lines"
							autoSize={{ minRows: 2, maxRows: 6 }}
							disabled
						/>)}
				</Form.Item> */}
			</Form>
		);
	}
}
