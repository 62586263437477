import React, { Component } from 'react';
import { Tabs, Button, Icon } from 'antd';
import MonthlyReportDetail from './MonthlyReportDetail';
import { getPledgesByOrganization, getTopUpByIndividual, getPledgesByBusinesses } from '../../../util/APIUtils';
import { monthArray, getFirstWord } from '../../../util/Helper';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import { 
	Skeleton, 
	// DatePicker 
} from 'antd';

const { TabPane } = Tabs;
// const { MonthPicker } = DatePicker
// const dateFormat = 'YYYY';
class MonthlyReports extends Component {
	constructor(props) {
		super(props);
		this.state = { dataOrg: [], dataIndividual: [], dataBiz: [], year: new Date().getFullYear() };
	}
	componentDidMount = () => {
		getPledgesByOrganization()
			.then((response) => {
				const data = [];
				response.map((item, key) => {
					let name;
					if(Object.keys(item.organization).length === 0){
						name = "Data is deleted"
					}else{
						name = item.organization.organization.organizationName
					}
					data.push({
						name: name,
						amount: item.pledgeReceived,
						create: new Date(item.organization.created_at).getMonth() + 1,
					});
					return null;
				});
				// const topValues = data.sort((a, b) => b - a).slice(0, 5);
				this.setState({
					dataOrg: data,
				});
			})
			.catch((err) => {});
		getTopUpByIndividual()
			.then((response) => {
				const data = [];
				response.map((item, key) => {
					data.push({
						name: item.individual.individual.name,
						email: item.individual.email,
						amount: item.toppedup,
						create: new Date(item.individual.created_at).getMonth() + 1,
					});
					return null;
				});
				// const topValues = data.sort((a, b) => b - a).slice(0, 5);
				this.setState({
					dataIndividual: data,
				});
			})
			.catch((err) => {});
		getPledgesByBusinesses()
			.then((response) => {
				const data = [];
				response.map((item, key) => {
					data.push({
						name: item.business.business.businessName,
						amount: item.pledged,
						create: new Date(item.business.created_at).getMonth() + 1,
					});
					return null;
				});

				this.setState({
					dataBiz: data,
				});
			})
			.catch((err) => {});
	};
	onChange(date, dateString) {
		console.log(date, dateString);
	}
	renderTableOrg(m) {
		const { dataOrg } = this.state;
		if (dataOrg.length === 0) {
			return <Skeleton active />;
		} else {
			let total = 0;
			let data = this.state.dataOrg;
			let dataFilter = data.filter((data) => data.create === m);
			const topValues = dataFilter.sort((a, b) => b - a).slice(0, 5);
			const dataTable = topValues.map((item, key) => {
				const { name, amount } = item; //destructuring
				total += amount;
				return (
					<tr key={key}>
						<td>{name}</td>
						<td>{amount.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
					</tr>
				);
			});
			const value = total.toLocaleString(undefined, { minimumFractionDigits: 0 });
			return (
				<table className="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						{dataTable}
						<tr>
							<td>Total:</td>
							<td>{value}</td>
						</tr>
					</tbody>
				</table>
			);
		}
	}
	renderTableIndividual(m) {
		const { dataIndividual } = this.state;
		if (dataIndividual.length === 0) {
			return <Skeleton active />;
		} else {
			let total = 0;
			let data = this.state.dataIndividual;
			let dataFilter = data.filter((data) => data.create === m);
			const topValues = dataFilter.sort((a, b) => b - a).slice(0, 5);
			const dataTable = topValues.map((item, key) => {
				const { name, email, amount } = item; //destructuring
				total += amount;
				return (
					<tr key={key}>
						{/* <td>{name}</td> */}
						<td><a href={"mailto:"+email}>{getFirstWord(name)}</a></td>
						{/* <td>{email}</td> */}
						<td>{amount.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
					</tr>
				);
			});
			const value = total.toLocaleString(undefined, { minimumFractionDigits: 0 });
			return (
				<table className="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						{dataTable}
						<tr>
							<td>Total:</td>
							<td>{value}</td>
						</tr>
					</tbody>
				</table>
			);
		}
	}
	renderTableBiz(m) {
		const { dataBiz } = this.state;
		if (dataBiz.length === 0) {
			return <Skeleton active />;
		} else {
			let total = 0;
			let data = this.state.dataBiz;
			let dataFilter = data.filter((data) => data.create === m);
			const topValues = dataFilter.sort((a, b) => b - a).slice(0, 5);
			const dataTable = topValues.map((item, key) => {
				const { name, amount } = item; //destructuring
				total += amount;
				return (
					<tr key={key}>
						<td>{name}</td>
						<td>{amount.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
					</tr>
				);
			});
			const value = total.toLocaleString(undefined, { minimumFractionDigits: 0 });
			return (
				<table className="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						{dataTable}
						<tr>
							<td>Total:</td>
							<td>{value}</td>
						</tr>
					</tbody>
				</table>
			);
		}
	}
	renderTabPane() {
		return monthArray.map((item, key) => {
			return (
				<TabPane tab={item} key={key + 1}>
					<MonthlyReportDetail
						dataBiz={this.renderTableBiz(key + 1)}
						biz={this.state.dataBiz}
						mbiz={key + 1}
						dataOrg={this.renderTableOrg(key + 1)}
						org={this.state.dataOrg}
						morg={key + 1}
						dataInd={this.renderTableIndividual(key + 1)}
						ind={this.state.dataIndividual}
						mind={key + 1}
					/>
				</TabPane>
			);
		});
	}
	saveAsExcel = async () => {
		const wb = new ExcelJS.Workbook();
		// create new sheet with pageSetup settings for A4 - landscape
		const ws = wb.addWorksheet('sheet', {
			pageSetup: { paperSize: 9, orientation: 'landscape' },
		});

		let dataB = [],
			dataI = [],
			dataO = [],
			row = 1,
			labelBiz = ['Name', 'Amount', 'Month'],
			labelIndividual = ['Name', 'Email', 'Amount', 'Month'],
			labelOrg = ['Name', 'Amount', 'Month'];

		let { dataBiz, dataIndividual, dataOrg } = this.state;

		console.log(dataBiz);

		// adjust pageSetup settings afterwards
		ws.pageSetup.margins = {
			left: 0.7,
			right: 0.7,
			top: 0.75,
			bottom: 0.75,
			header: 0.3,
			footer: 0.3,
		};

		dataBiz.map((item, key) => {
			dataB.push([item.name, item.amount, item.create]);
			return null;
		});

		dataIndividual.map((item, key) => {
			dataI.push([item.name, item.email, item.amount, item.create]);
			return null;
		});

		dataOrg.map((item, key) => {
			dataO.push([item.name, item.amount, item.create]);
			return null;
		});

		const titleBiz = ws.addRow(['Top Pledging Businesses-' + new Date().getFullYear()]);
		ws.mergeCells('A' + row + ':C' + row);
		row += 1;
		titleBiz.font = { bold: true };
		titleBiz.alignment = { vertical: 'middle', horizontal: 'center' };

		const title = ws.addRow(labelBiz);
		title.font = { bold: true };
		row += 1;

		dataB.map((item, key) => {
			ws.addRow(item);
			row += 1;
			return null;
		});

		ws.addRow();
		row += 1;
		const titleIndividual = ws.addRow(['Top Purchasing Individuals-' + new Date().getFullYear()]);
		ws.mergeCells('A' + row + ':D' + row);
		row += 1;
		titleIndividual.font = { bold: true };
		titleIndividual.alignment = { vertical: 'middle', horizontal: 'center' };

		const title2 = ws.addRow(labelIndividual);
		title2.font = { bold: true };
		row += 1;

		dataI.map((item, key) => {
			ws.addRow(item);
			row += 1;
			return null;
		});

		ws.addRow();
		row += 1;
		const titleOrg = ws.addRow(['Top Receiving Organizations-' + new Date().getFullYear()]);
		ws.mergeCells('A' + row + ':C' + row);
		row += 1;
		titleOrg.font = { bold: true };
		titleOrg.alignment = { vertical: 'middle', horizontal: 'center' };

		const title3 = ws.addRow(labelOrg);
		title3.font = { bold: true };

		dataO.map((item, key) => {
			ws.addRow(item);
			return null;
		});
		const nameFile = 'MonthlyReport-' + new Date().getFullYear() + '.xlsx';
		const buf = await wb.xlsx.writeBuffer();
		saveAs(new Blob([buf]), nameFile);
	};
	render() {
		return (
			<div className="x_panel">
				<div className="row">
					<div className="col-lg-6 col-xs-12">
						<h4>Monthly Reports</h4>
					</div>
					<div className="col-lg-6 col-xs-12 text-right">
						<div className="row">
							<div className="col-lg-8 col-xs-12">
								{/* <MonthPicker format={dateFormat} onChange={this.onChange()} /> */}
							</div>
							<div className="col-lg-4 col-xs-12">
								<Button type="primary" onClick={() => this.saveAsExcel()} block>
									<Icon type="save" /> Export
								</Button>
							</div>
						</div>
					</div>
				</div>

				<div className="x_title">
					<div className="clearfix"></div>
				</div>
				<div className="x_content">
					<Tabs defaultActiveKey="1">{this.renderTabPane()}</Tabs>
				</div>
			</div>
		);
	}
}

export default MonthlyReports;
