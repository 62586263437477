import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

class MonthlyReportDetail extends Component {
	saveAsExcelBiz = async () => {
		const wb = new ExcelJS.Workbook();
		// create new sheet with pageSetup settings for A4 - landscape
		const ws = wb.addWorksheet('sheet', {
			pageSetup: { paperSize: 9, orientation: 'landscape' },
		});

		let datas = [],
			label = ['Name', 'Amount'],
			row = 1;

		let { biz } = this.props;
		let dataFilter = biz.filter((data) => data.create === this.props.mbiz);
		const topValues = dataFilter.sort((a, b) => b - a).slice(0, 5);

		// adjust pageSetup settings afterwards
		ws.pageSetup.margins = {
			left: 0.7,
			right: 0.7,
			top: 0.75,
			bottom: 0.75,
			header: 0.3,
			footer: 0.3,
		};

		topValues.map((item, key) => {
			datas.push([item.name, item.amount]);
			return null;
		});
		const titleBiz = ws.addRow(['Pledges Received from Businesses-' + this.props.mbiz]);
		titleBiz.alignment = { vertical: 'middle', horizontal: 'center' };
		ws.mergeCells('A' + row + ':B' + row);
		titleBiz.font = { bold: true };
		ws.addRow();
		const title = ws.addRow(label);
		title.font = { bold: true };

		datas.map((item, key) => {
			ws.addRow(item);
			return null;
		});
		const nameFile = 'PledgesReceivedfromBusinesses-' + this.props.mbiz + '.xlsx';
		const buf = await wb.xlsx.writeBuffer();
		saveAs(new Blob([buf]), nameFile);
	};
	saveAsExcelOrg = async () => {
		const wb = new ExcelJS.Workbook();
		// create new sheet with pageSetup settings for A4 - landscape
		const ws = wb.addWorksheet('sheet', {
			pageSetup: { paperSize: 9, orientation: 'landscape' },
		});

		let datas = [],
			label = ['Name', 'Amount'],
			row = 1;

		let { org } = this.props;
		let dataFilter = org.filter((data) => data.create === this.props.morg);
		const topValues = dataFilter.sort((a, b) => b - a).slice(0, 5);

		// adjust pageSetup settings afterwards
		ws.pageSetup.margins = {
			left: 0.7,
			right: 0.7,
			top: 0.75,
			bottom: 0.75,
			header: 0.3,
			footer: 0.3,
		};

		topValues.map((item, key) => {
			datas.push([item.name, item.amount]);
			return null;
		});
		const titleOrg = ws.addRow(['Top Receiving Organizations-month ' + this.props.morg]);
		titleOrg.alignment = { vertical: 'middle', horizontal: 'center' };
		ws.mergeCells('A' + row + ':B' + row);
		titleOrg.font = { bold: true };
		ws.addRow();
		const title = ws.addRow(label);
		title.font = { bold: true };

		datas.map((item, key) => {
			ws.addRow(item);
			return null;
		});
		const nameFile = 'TopReceivingOrganizations-' + this.props.morg + '.xlsx';
		const buf = await wb.xlsx.writeBuffer();
		saveAs(new Blob([buf]), nameFile);
	};
	saveAsExcelInd = async () => {
		const wb = new ExcelJS.Workbook();
		// create new sheet with pageSetup settings for A4 - landscape
		const ws = wb.addWorksheet('sheet', {
			pageSetup: { paperSize: 9, orientation: 'landscape' },
		});

		let datas = [],
			label = ['Name', 'Email', 'Amount'],
			row = 1;

		let { ind } = this.props;
		let dataFilter = ind.filter((data) => data.create === this.props.mind);
		const topValues = dataFilter.sort((a, b) => b - a).slice(0, 5);

		// adjust pageSetup settings afterwards
		ws.pageSetup.margins = {
			left: 0.7,
			right: 0.7,
			top: 0.75,
			bottom: 0.75,
			header: 0.3,
			footer: 0.3,
		};

		topValues.map((item, key) => {
			datas.push([item.name, item.email, item.amount]);
			return null;
		});
		const titleInd = ws.addRow(['Top Supporting Individuals-month ' + this.props.mind]);
		titleInd.alignment = { vertical: 'middle', horizontal: 'center' };
		ws.mergeCells('A' + row + ':C' + row);
		titleInd.font = { bold: true };
		ws.addRow();
		const title = ws.addRow(label);
		title.font = { bold: true };

		datas.map((item, key) => {
			ws.addRow(item);
			return null;
		});
		const nameFile = 'TopSupportingIndividuals-' + this.props.mind + '.xlsx';
		const buf = await wb.xlsx.writeBuffer();
		saveAs(new Blob([buf]), nameFile);
	};
	render() {
		return (
			<div className="row">
				<div className="col-md-4 col-sm-4  ">
					<div className="x_panel">
						<div className="col-lg-7 col-xs-12">
								<h4>
								Pledges Received
								<br />
								from Businesses
							</h4>
							</div>
							<div className="col-lg-5 col-xs-12 text-right">
								<Button type="primary" onClick={() => this.saveAsExcelBiz()} block>
									<Icon type="save" /> Export
								</Button>
							</div>
						<div className="x_content table-responsive">{this.props.dataBiz}</div>
					</div>
				</div>
				<div className="col-md-4 col-sm-4  ">
					<div className="x_panel">
						<div className="row">
							<div className="col-lg-7 col-xs-12">
								<h4>
									Top Supporting
									<br />
									Individuals
								</h4>
							</div>
							<div className="col-lg-5 col-xs-12 text-right">
								<Button type="primary" onClick={() => this.saveAsExcelOrg()} block>
									<Icon type="save" /> Export
								</Button>
							</div>
						</div>
						<div className="x_content table-responsive">{this.props.dataInd}</div>
					</div>
				</div>
				<div className="col-md-4 col-sm-4  ">
					<div className="x_panel">
						<div className="row">
							<div className="col-lg-7 col-xs-12">
								<h4>Top Receiving Organizations</h4>
							</div>
							<div className="col-lg-5 col-xs-12 text-right">
								<Button type="primary" onClick={() => this.saveAsExcelInd()} block>
									<Icon type="save" /> Export
								</Button>
							</div>
						</div>
						<div className="x_content table-responsive">{this.props.dataOrg}</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MonthlyReportDetail;
