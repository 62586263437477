import React, { Component } from 'react';
import { Tabs, Button, Icon, Modal, notification, Form, Input, Skeleton, Select } from 'antd';
import { getIndividuals, getListTransferTransactions } from '../../../util/APIUtils';
import { formatDate } from '../../../util/Helper';

const FormItem = Form.Item

export default class TransactionComponent extends Component {
    constructor() {
        super();
        //this.state = { id: this.props.id, dataFinance: [], visible: false };
        this.state = {
            visible: false,
            transactions: []
        }
    }

    componentDidMount() {
        let data = []
        getListTransferTransactions()
            .then((response) => {
                response.map(item => {
                    data.push(item)
                    return null
                })
                this.setState({ transactions: data })
            }).catch(err => console.error(err))
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <div className="x_content">
                <div className="row">
                    <div className="col-lg-10">
                        <h3>  </h3>
                    </div>
                    <div className="col-lg-2 col-xs-12">
                        <Button type="primary" onClick={this.showModal} block>
                            <Icon type="wallet" /> Transfer
                        </Button>
                    </div>

                    <Modal
                        title='Transfer To'
                        visible={this.state.visible}
                        onCancel={this.handleCancel}
                        footer={[
                            <Button key="back" onClick={this.handleCancel}>
                                Cancel
                            </Button>,
                        ]}
                    >
                        <TransferForm />
                    </Modal>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="x_panel">
                            <div className="x_content table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Individual</th>
                                            <th>Transfer By</th>
                                            <th style={{ textAlign: 'right' }}>Amount</th>
                                            <th style={{ textAlign: 'right' }}>Balance Before</th>
                                            <th style={{ textAlign: 'right' }}>Balance After</th>
                                            <th style={{ textAlign: 'right' }}>Current Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.transactions.map((item, index) => (
                                                <tr>
                                                    <td>{formatDate(new Date(item.created_at))}</td>
                                                    <td>
                                                        <strong>{item?.individual?.name}</strong>
                                                    </td>
                                                    <td>{item?.cordinator?.username}</td>
                                                    <td align='right'><strong>{item?.amount.toLocaleString(undefined, { minimumFractionDigits: 0 })} </strong></td>
                                                    <td align='right'>{item?.balance_before.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
                                                    <td align='right'>{item?.balance_after.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
                                                    <td align='right'>{(item?.individual?.balance || 0).toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class TransferForm extends Component {
    constructor() {
        super()
        this.state = {
            loading : false,
            amount : null,
            individuals: []
        }
    }

    componentDidMount(){
        this.getIndividual()
    }


    getIndividual(){
        let data = []
        getIndividuals()
        .then((response)=>{
            response.map((item)=>{
                data.push(item)
            })

            this.setState({individuals : data})
        }).catch(err=> console.error(err))
    }
    render() {
        const {loading, amount, individuals } = this.state;
        
        return (
            <Form className='login-form'>
                <FormItem label='Transfer to : '>
                   <Select>
                        {
                            individuals.map((item, key)=>(
                                <Select.Option key={key} value={item.id}>{item.name} ({item?.user?.username})</Select.Option>
                            ))
                        }
                    </Select>
                </FormItem>
                <FormItem label='Amount'>
                    <Input prefix={<Icon type="wallet" />}
                        size="large"
                        type='number'
                        value={amount}
                        placeholder="Transfer Amount" />
                </FormItem>

                <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="login-form-button"
                    loading={loading}
                >
                    Transfer
                </Button>
            </Form>
        )
    }
}