import React, { Component } from 'react';
import { Form, Input, Icon, Tooltip, Upload } from 'antd';
import { Link } from 'react-router-dom';
import { getCurrentUser } from '../../util/APIUtils';
import { Helmet } from 'react-helmet';
import StatsLists from './StatsLists';

// const { TextArea } = Input;
export default class Profile extends Component {
	render() {
		const AntWrappedProfileForm = Form.create()(ProfileForm);
		return (
			<div className="right_col" role="main">
				<Helmet>
					<title>Organization</title>
				</Helmet>
				<StatsLists />
				<div className="row">
					<div className="col-md-12 col-sm-12 ">
						<div className="dashboard_graph">
							<div className="row x_title">
								<div className="col-md-6">
									<h3>Profile</h3>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12"></div>
							<div className="col-md-12 col-sm-12">
								<div className="x_panel">
									<div className="x_title">
										<Link to="/edit-profile" className="btn btn-primary">
											Edit
										</Link>
										<div className="clearfix"></div>
									</div>
									<div className="x_content">
										<AntWrappedProfileForm />
									</div>
								</div>
							</div>
							<div className="clearfix"></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class ProfileForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			email: '',
			name: '',
			orgName: '',
			description: '',
			goal: '',
			phone: '',
			address: '',
			bankName: '',
			bankAccountHolder: '',
			bankNumber: '',
			whatwedo: '',
			summary: '',
			website: '',
			loading: false,
			avatar: '',
		};
	}

	componentDidMount = () => {
		getCurrentUser()
			.then((response) => {
				// console.log(response);
				let image = '';
				if (response.organization.avatar === null) {
					image = '';
				} else {
					image = response.organization.avatar.url;
				}
				console.log(response)
				this.setState({
					username: response.username,
					email: response.email,
					name: response.organization.name,
					orgName: response.organization.organizationName,
					description: response.organization.description,
					goal: response.organization.goal,
					phone: response.organization.phone,
					address: response.organization.address,
					bankName: response.organization.bankName,
					bankAccountHolder: response.organization.bankAccountHolder,
					bankNumber: response.organization.bankNumber,
					whatwedo: response.organization.whatwedo,
					summary: response.organization.summary,
					website: response.organization.website,
					avatar: image,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	onChange = (value) => {
		this.setState({
			inputValue: value,
		});
	};

	render() {
		// const { username, email, name, orgName, description, address, bankName, bankAccountHolder, bankNumber, phone, summary, goal, whatwedo, website } = this.state;
		const {
			username,
			email,
			orgName,
			description,
			address,
			bankName,
			bankAccountHolder,
			bankNumber,
			phone,
			goal,
			website,
		} = this.state;
		const { getFieldDecorator } = this.props.form;
		const formItemLayout = {
			labelCol: {
				xs: { span: 16 },
				sm: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 16 },
				sm: { span: 8 } /*  */,
			},
		};
		const uploadButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		const { avatar } = this.state;
		return (
			<Form {...formItemLayout}>
				<Form.Item label="Avatar">
					{getFieldDecorator('files.avatar', {
						rules: [{ required: true, message: 'Please input your avatar!' }],
					})(
						<Upload
							name="avatar"
							listType="picture-card"
							className="avatar-uploader"
							showUploadList={false}
							action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
							disabled
						>
							{avatar ? <img src={avatar} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
						</Upload>
					)}
				</Form.Item>
				<Form.Item label="Username">
					{getFieldDecorator('username', {
						rules: [{ required: true, message: 'Please input your username!' }],
						initialValue: username,
					})(<Input disabled />)}
				</Form.Item>
				<Form.Item label="E-mail">
					{getFieldDecorator('email', {
						rules: [
							{
								type: 'email',
								message: 'The input is not valid E-mail!',
							},
							{
								required: true,
								message: 'Please input your E-mail!',
							},
						],
						initialValue: email,
					})(<Input disabled />)}
				</Form.Item>

				{/* <Form.Item
					label={
						<span>
							Name&nbsp;
							<Tooltip title="What do you want others to call you?">
								<Icon type="question-circle-o" />
							</Tooltip>
						</span>
					}
				>
					{getFieldDecorator('name', {
						rules: [{ required: true, message: 'Please input your name!', whitespace: true }],
						initialValue: name,
					})(<Input disabled />)}
				</Form.Item> */}

				<Form.Item
					label={
						<span>
							Organization Name&nbsp;
							<Tooltip title="What do you want others to call your organization?">
								<Icon type="question-circle-o" />
							</Tooltip>
						</span>
					}
				>
					{getFieldDecorator('organizationName', {
						rules: [{ required: true, message: 'Please input your organization name!', whitespace: true }],
						initialValue: orgName
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Description">
					{getFieldDecorator('description', {
						rules: [{ required: true, message: 'Please input your description!' }],
						initialValue: description,
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Website">
					{getFieldDecorator('website', {
						rules: [{ required: true, message: 'Please input your website!' }],
						initialValue: website,
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Fundraising Goal">
					{getFieldDecorator('goal', {
						rules: [{ required: true, message: 'Please input your fundraising goal!' }],
						initialValue: goal,
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Phone Number">
					{getFieldDecorator('phone', {
						rules: [{ required: true, message: 'Please input your phone number!' }],
						initialValue: phone,
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Address">
					{getFieldDecorator('address', {
						rules: [{ required: true, message: 'Please input your address!' }],
						initialValue: address,
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Bank Name">
					{getFieldDecorator('bankName', {
						rules: [{ required: true, message: 'Please input your bank name!' }],
						initialValue: bankName,
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Bank Account Holder">
					{getFieldDecorator('bankAccount', {
						rules: [{ required: true, message: 'Please input your bank account holder!' }],
						initialValue: bankAccountHolder,
					})(<Input disabled />)}
				</Form.Item>

				<Form.Item label="Account Number">
					{getFieldDecorator('bankNumber', {
						rules: [{ required: true, message: 'Please input your account number!' }],
						initialValue: bankNumber,
					})(<Input disabled />)}
				</Form.Item>

				{/* <Form.Item label="What We Do">
					{getFieldDecorator('whatwedo', {
						rules: [{ required: true, message: 'Please input your What We Do!' }],
						initialValue: whatwedo,
					})(<TextArea placeholder="What we do" autoSize={{ minRows: 2, maxRows: 6 }} disabled />)}
				</Form.Item> */}

				{/* <Form.Item label="About Us">
					{getFieldDecorator('summary', {
						rules: [{ required: true, message: 'Please input your about us!' }],
						initialValue: summary,
					})(<TextArea placeholder="About us" autoSize={{ minRows: 2, maxRows: 6 }} disabled />)}
				</Form.Item> */}
			</Form>
		);
	}
}
