import React, { Component } from 'react';
import RecentNotification from '../../../components/RecentNotification';
import { getNotification } from '../../../util/APIUtils';

import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import { Button, Icon, Skeleton } from 'antd';

class NotificationGeneral extends Component {
	constructor(props) {
		super(props);
		this.state = { data: { datas: [] } };
	}
	componentDidMount = () => {
		const data = [];
		getNotification(5)
			.then((response) => {
				response.map((item, key) => {
					data.push({
						title: item.data.title,
						body: item.data.body,
						sender: item.sender.username,
						createdAt: item.created_at,
					});
					return null;
				});
				this.setState({
					data: {
						datas: data,
					},
				});
			})
			.catch((err) => {});
	};
	renderTableData() {
		const { data } = this.state;
		if (data.datas.length === 0) {
			return <Skeleton active />;
		} else {
			const dataTable = this.state.data.datas.map((item, key) => {
				const { title, body, sender, createdAt } = item; //destructuring
				return (
					<RecentNotification key={key} title={title} body={body} sender={sender} createdaAt={createdAt} />
				);
			});
			return (
				<div className="dashboard-widget-content">
					<ul className="list-unstyled timeline widget">{dataTable}</ul>
				</div>
			);
		}
	}
	saveAsExcel = async () => {
		const wb = new ExcelJS.Workbook();
		// create new sheet with pageSetup settings for A4 - landscape
		const ws = wb.addWorksheet('sheet', {
			pageSetup: { paperSize: 9, orientation: 'landscape' },
		});

		let datas = [],
			label = ['Title', 'Body', 'Sender', 'Create At'];

		let { data } = this.state;

		// adjust pageSetup settings afterwards
		ws.pageSetup.margins = {
			left: 0.7,
			right: 0.7,
			top: 0.75,
			bottom: 0.75,
			header: 0.3,
			footer: 0.3,
		};

		data.datas.map((item, key) => {
			datas.push([item.title, item.body, item.sender, item.createdAt]);
			return null;
		});

		const title = ws.addRow(label);
		title.font = { bold: true };

		datas.map((item, key) => {
			ws.addRow(item);
			return null;
		});

		const buf = await wb.xlsx.writeBuffer();
		saveAs(new Blob([buf]), 'Top 5 Recent Notifications.xlsx');
	};
	render() {
		return (
			<div className="x_panel">
				<div className="row">
					<div className="col-lg-10">
						<h4>Recent Notifications</h4>
					</div>
					<div className="col-lg-2 col-xs-12 text-right">
						<Button type="primary" onClick={() => this.saveAsExcel()} block>
							<Icon type="save" /> Export
						</Button>
					</div>
				</div>
				<div className="x_title">
					<div className="clearfix"></div>
				</div>
				<div className="x_content">{this.renderTableData()}</div>
			</div>
		);
	}
}

export default NotificationGeneral;
