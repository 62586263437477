// export function formatDate(dateString) {
//     const date = new Date(dateString);

//     const monthNames = [
//       "January", "February", "March",
//       "April", "May", "June", "July",
//       "August", "September", "October",
//       "November", "December"
//     ];

//     const monthIndex = date.getMonth();
//     const year = date.getFullYear();

//     return monthNames[monthIndex] + ' ' + year;
// }

import moment from 'moment';

export function formatDateTime(dateTimeString) {
	const date = new Date(dateTimeString);

	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	const monthIndex = date.getMonth();
	const year = date.getFullYear();

	return (
		date.getDate() + ' ' + monthNames[monthIndex] + ' ' + year + ' - ' + date.getHours() + ':' + date.getMinutes()
	);
}

export function formatDate(date) {
	var d = new Date(date),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
}

export function getDay(date) {
	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	var d = new Date(date),
		day = d.getDate() + monthNames[d.getMonth()];
	return day;
}

export function timeSince(date) {
	var seconds = Math.floor((new Date() - date) / 1000);

	var interval = seconds / 31536000;

	if (interval > 1) {
		return Math.floor(interval) + ' years';
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) + ' months';
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + ' days';
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + ' hours';
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + ' minutes';
	}
	return Math.floor(seconds) + ' seconds';
}

export function getRelativeTime(date) {
	return moment().startOf(date).fromNow();
}

export const colorRGBA = [
	'rgba(41, 128, 185, 0.75)',
	'rgba(26, 187, 156, 0.75)',
	'rgba(231, 76, 60, 0.75)',
	'rgba(179, 107, 182, 0.75)',
	'rgba(196, 196, 196, 0.75)',
	'rgba(45, 97, 135, 0.75)',
	'rgba(168, 221, 168, 0.75)',
];
export const colorHex = ['#2980b9', '#1ABB9C', '#E74C3C', '#B36BB6', '#c4c4c4', '#2d6187', '#a8dda8'];

export const monthArray = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const findPercent = (first, last) => {
	let percent = (first/last).toFixed(2) * 100
	return percent.toFixed(1)
}

export function getFirstWord(str) {
	let spaceIndex = str.indexOf(' ');
	return spaceIndex === -1 ? str : str.substr(0, spaceIndex);
};