import React, { Component } from 'react';
import { getListPledges } from '../../util/APIUtils';

import StatsLists from './StatsLists';
import PledgeComponent from './PledgeComponent';
import { Carousel } from 'antd';
import { Helmet } from 'react-helmet';
import PledgeInfo from './PledgeInfo';

export default class Pledge extends Component {
	constructor(props) {
		super(props);
		this.state = { data: { datas: [] }, step: 0, amount: 0, showPledgeInfo: false };
		this.carousel = React.createRef();
	}
	componentDidMount = () => {
		const data = [];
		getListPledges()
			.then((response) => {
				console.log(response)
				response.map((item, key) => {
					let image = "";
					if (item.organization.avatar === null) {
						image = ""
					} else {
						image = item.organization.avatar.url
					}
					data.push({
						id: item.id,
						name: item.organization.organizationName,
						whatwedo: item.organization.whatwedo,
						goal: item.organization.goal,
						email: item.email,
						phone: item.organization.phone,
						address: item.organization.address,
						details: item.organization.description,
						image: image,
						summary: item.organization.summary,
					});
					return null;
				});
				this.setState({
					data: {
						datas: data,
					},
				});
			})
			.catch((err) => {
				console.log(err)
			});
	};
	next = () => {
		this.carousel.next();
	};
	previous = () => {
		this.carousel.prev();
	};

	renderSlideData() {
		const dataSlide = this.state.data.datas.map((item, key) => {
			const { id, name, whatwedo, goal, email, phone, address, details, image, summary } = item; //destructuring
			return (
				<PledgeComponent
					id={id}
					name={name}
					image={image}
					doing={whatwedo}
					goal={goal}
					email={email}
					phone={phone}
					address={address}
					detail={details}
					summary={summary}
					key={id}
					onShowPledgeInfo={() => this.setState({ showPledgeInfo: !this.state.showPledgeInfo })}
				/>
			);
		});
		return dataSlide;
	}
	render() {
		return (
			<div className="right_col" role="main">
				<Helmet>
					<title>Business</title>
				</Helmet>
				<StatsLists />
				<div className="row">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<div className="dashboard_graph">
							<div className="row x_title">
								<div className="col-md-12">
									<h3> Pledge </h3>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12"></div>
							<div className="col-md-12 col-sm-12 col-xs-12">
								{
									this.state.showPledgeInfo ? (
										<div className='x_panel'>
											<div className='x_content'>
												<PledgeInfo onShowPledgeInfo={() => this.setState({ showPledgeInfo: !this.state.showPledgeInfo })} />
											</div>
										</div>
									) : (
										<div className="x_panel">
											<div className="x_title">
												<span className="prev available" onClick={this.previous}>
													<i className="fa fa-chevron-left glyphicon glyphicon-chevron-left"></i>{' '}
													Previous
												</span>
												<span className="month">-</span>
												<span className="next available" onClick={this.next}>
													Next{' '}
													<i className="fa fa-chevron-right glyphicon glyphicon-chevron-right"></i>
												</span>
												<div className="clearfix"></div>
											</div>
											<div className="x_content">
												<Carousel autoplay ref={(node) => (this.carousel = node)}>
													{this.renderSlideData()}
												</Carousel>
											</div>
										</div>
									)
								}
							</div>
							<div className="clearfix"></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
