import React, { Component } from 'react'

import { Link } from 'react-router-dom';

import StatsLists from './StatsLists';

class Settings extends Component {
    render() {
        return (
            <div className="right_col" role="main">
				<StatsLists />
				<div className="row">
					<div className="col-md-12 col-sm-12 ">
						<div className="dashboard_graph">
							<div className="row x_title">
								<div className="col-md-6">
									<h3>Profile</h3>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12"></div>
							<div className="col-md-12 col-sm-12">
								<div className="x_panel">
									<div className="x_title">
										<Link to="/edit-settings" className="btn btn-primary">
											Edit
										</Link>
										<div className="clearfix"></div>
									</div>
									<div className="x_content">
										<form action="" method="post" novalidate>
											<span className="section">Personal Info</span>
											<div className="row m-2">
												<label className="col-form-label col-md-3 col-sm-3 text-right">
													Personal Name
													<span className="required">*</span>
												</label>
												<div className="col-md-6 col-sm-6">
													<input
														className="form-control"
														data-validate-length-range="6"
														data-validate-words="2"
														individual_name="name"
														required="required"
													/>
												</div>
											</div>
											<div className="row m-2">
												<label className="col-form-label col-md-3 col-sm-3 text-right">
													Personal Email
													<span className="required">*</span>
												</label>
												<div className="col-md-6 col-sm-6">
													<input
														className="form-control"
														name="owner_email"
														required="required"
														type="email"
													/>
												</div>
											</div>

											<div className="row m-2">
												<label className="col-form-label col-md-3 col-sm-3 text-right">
													Business Name&nbsp;
													<span className="required">*</span>
												</label>
												<div className="col-md-6 col-sm-6">
													<input
														className="form-control"
														name="business_name"
														data-validate-length-range="5,15"
														type="text"
													/>
												</div>
											</div>
											<div className="row m-2">
												<label className="col-form-label col-md-3 col-sm-3 text-right">
                                                    Business Email
													<span className="required">*</span>
												</label>
												<div className="col-md-6 col-sm-6">
													<input
														className="form-control"
														name="email"
														required="required"
														type="email"
													/>
												</div>
											</div>
											<div className="row m-2">
												<label className="col-form-label col-md-3 col-sm-3 text-right">
													Address
													<span className="required">*</span>
												</label>
												<div className="col-md-6 col-sm-6">
													<textarea required="required" name="business_address"></textarea>
												</div>
											</div>
											<div className="row m-2">
												<label className="col-form-label col-md-3 col-sm-3 text-right">
                                                    Percentage Charged (Applied to Contributions)
													<span className="required">*</span>
												</label>
												<div className="col-md-6 col-sm-6">
													<input
														className="form-control"
														type="Business Summary"
														name="business_summary"
														data-validate-linked="email"
														required="required"
													/>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div className="clearfix"></div>
						</div>
					</div>
				</div>
			</div>
        )
    }
}

export default Settings