import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../context';
import { Link } from 'react-router-dom'
import { notification } from 'antd';
import { ACCESS_TOKEN } from '../../constants';


import ProfileList from './topnav-list/ProfileList'
function Topnav(props) {
	const { onChangeSidebar } = useContext(Context);
	const [username, setUsername] = useState("");
	const [avatar, setAvatar] = useState(null);
	const [role, setRole] = useState("Coordinator");

	useEffect(() => {
		setUsername(props.userInfo.username);
		if(props.userInfo.role.type === 'organization'){
			if(props.userInfo.organization.avatar !== null){
				setAvatar(props.userInfo.organization.avatar.url)
				setUsername(props.userInfo.organization.organizationName)
				setRole("Organization")
			}
		}else if(props.userInfo.role.type === 'business'){
			if(props.userInfo.business.avatar !== null){
				setAvatar(props.userInfo.business.avatar.url)
				setUsername(props.userInfo.business.businessName)
				setRole("Business")
			}
		}else{

		}
	}, [props.userInfo]);

	const handleLogout = (notificationType="success", description="You're successfully logged out.") => {
		localStorage.removeItem(ACCESS_TOKEN);
	
		notification[notificationType]({
		  message: role,
		  description: description,
		});

		setTimeout((props) => {
			window.location.reload();
		}, 3000)
	  }
	const renderProfile = () => {
		if(avatar !== null){
			return <img src={avatar} alt="..." />
		}else{
			return ""
		}
	}
	return (
		<div className="top_nav">
			<div className="nav_menu">
				<nav>
					<div className="nav toggle">
						<Link onClick={onChangeSidebar} >
							<i className="fa fa-bars"></i>
						</Link>
					</div>

					<ul className="nav navbar-nav navbar-right">
						<li className="">
							<Link
								className="user-profile dropdown-toggle"
								data-toggle="dropdown"
								aria-expanded="false"
							>
								{ renderProfile() }
								{ username }
								<span className=" fa fa-angle-down"></span>
							</Link>
							<ProfileList handleLogout={() => handleLogout()} />
						</li>

						{/* <li role="presentation" className="dropdown">
							<Link
								className="dropdown-toggle info-number"
								data-toggle="dropdown"
								aria-expanded="false"
							>
								<i className="fa fa-envelope-o"></i>
								<span className="badge bg-green">6</span>
							</Link>
							<AnnounceLists />
						</li> */}
					</ul>
				</nav>
			</div>
		</div>
	);
}

export default Topnav;
