import React, { Component } from 'react'
import './Login.css';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../../util/APIUtils';
import { Form, Input, Icon, Button, Card, notification} from 'antd';

const FormItem = Form.Item;

class Forgot extends Component {
    render() {
        const AntWrappedLoginForm = Form.create()(ForgotForm)
        return (
            <div className="login-container">
                <div className="login-body">
                    <img src="Logo.png" alt="Logo" style={{  height: '128px' }} />
                    <h3>Forgot Password</h3>
                    <Card bordered={false} style={{ width: 300 }}>
                        <div className="login-content">
                            <AntWrappedLoginForm onLogin={this.props.onLogin} />
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

class ForgotForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // do login
                const loginRequest = Object.assign({}, values);
                forgotPassword(loginRequest)
                    .then(response => {
                        notification.success({
                            message: 'Platform-Coordinator',
                            description: 'Link has send in your email'
                        });
                    }).catch(error => {
                        if (error.statusCode === 400) {
                            notification.error({
                                message: 'Platform-Coordinator',
                                description: 'Your email is incorrect. Please try again!'
                            });
                        } else {
                            notification.error({
                                message: 'Platform-Coordinator',
                                description: 'Sorry! Something went wrong. Please try again!'
                            });
                        }
                    });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <FormItem>
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Please input your email!' }],
                    })(
                        <Input
                            prefix={<Icon type="mail" />}
                            size="large"
                            name="email"
                            placeholder="Email" />
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" size="large" className="login-form-button">Send</Button>
                    <Link to="/login">Back </Link>
                </FormItem>
            </Form>
        );
    }
}

export default Forgot