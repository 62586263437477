import React, { Component } from 'react';
import { PlatformCoordinator, Business, Organization } from './sidebar-list'
import { Context } from '../../context';
import { Link } from 'react-router-dom'

let loc = "";
export default class Sidebar extends Component {
	constructor(props){
		super(props)
		this.state = {
			role: "",
			titleRole: "",
			username: "",
			avatar: null,
		}
	}
	componentDidMount(){
		loc = this.props.location.location
		this.setState({
			location: this.props.location.location,
			role: this.props.userStatus,
			username: this.props.userInfo.username
		})
		if(this.props.userStatus === 'business'){
			this.setState({titleRole: 'Business'})
			if(this.props.userInfo.business.avatar !== null){
				this.setState({
					avatar: this.props.userInfo.business.avatar.url,
					username: this.props.userInfo.business.businessName
				})
			}
		}else if(this.props.userStatus === 'organization'){
			this.setState({titleRole: 'Organization'})
			if(this.props.userInfo.organization.avatar !== null){
				this.setState({
					avatar: this.props.userInfo.organization.avatar.url,
					username: this.props.userInfo.organization.organizationName
				})
			}
		}else{
			this.setState({titleRole: 'Coordinator'})
		}
	}
	componentWillUpdate(nextProps, nextState){
		loc = nextProps.location.location.pathname
	}
	renderProfile = () => {
		if(this.state.avatar !== null){
			return <img src={this.state.avatar} alt="..." className="img-circle profile_img" />
		}else{
			// return <img src="images/img.jpg" alt="..." className="img-circle profile_img" />
			return ""
		}
	}
	render() {
		const { role, username} = this.state
		return (
			<div className="col-md-3 left_col">
				<div className="left_col scroll-view">
					<div className="navbar nav_title" style={{ border: '0' }}>
						<Link to={'/'} className="site_title" onClick={this.context.onChangeSidebar}>
						<img src="CrowdCycle-logo.jpg" alt="Logo" style={{  height: '32px', marginLeft: '8px' }} /> <span className="blue">  {this.state.titleRole}</span>
						</Link>
					</div>

					<div className="clearfix"></div>

					<div className="profile clearfix">
						<div className="profile_pic">
							{this.renderProfile()}
						</div>
						<div className="profile_info">
							<span>Welcome,</span>
							<h2>{username}</h2>
						</div>
					</div>

					<br />

					{/* ini list nya */}

					{
						role === "cordinator" ? <PlatformCoordinator loc={loc} /> : 
						role === "business" ? <Business loc={loc} /> :
						role === "organization" ? <Organization loc={loc} /> : ""	
					}
				</div>
			</div>
		);
	}
}
Sidebar.contextType = Context;