import React, { Component } from 'react';
import {
	MonthlyReports,
	PledgedPurchasedGradient,
	ThisMonthDoughnuts,
	MyPledgesToOrganizations,
	MyTopPurchasingIndividuals,
	TopReceivingOrganizations,
} from './dashboardComponents';

import StatsLists from './StatsLists';
import { Helmet } from 'react-helmet';
import { DatePicker } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const date = new Date();

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = { date: null };
	}

	onChangeRange = (value, dateString) => {
		this.setState({ date: value });
	};

	onOkRange = (value) => {
		console.log('onOk: ', value);
	};
	render() {
		return (
			<div className="right_col" role="main">
				<Helmet>
					<title>Business</title>
				</Helmet>
				<StatsLists />
				<div className="row">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<div className="dashboard_graph">
							<div className="row x_title">
								<div className="col-md-6">
									<h3>
										Platform Activity <br />
										<small>Pledged vs Received</small>
									</h3>
								</div>
								<div className="col-md-6">
									<div
										id="reportrange"
										className="pull-right"
										style={{
											background: '#fff',
											padding: '5px 10px',
										}}
									>
										<RangePicker
											defaultValue={[
												moment(new Date(date.getFullYear(), date.getMonth(), 1), dateFormat),
												moment(new Date(date.getFullYear(), date.getMonth() + 1, 0), dateFormat),
											]}
											format={dateFormat}
											onChange={this.onChangeRange}
											onOk={this.onOkRange}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-8 col-sm-12 col-xs-12">
								{/* <div id="chart_plot_01" className="demo-placeholder"></div> */}
								<PledgedPurchasedGradient date={this.state.date} user={this.props.userInfo} />
							</div>
							<div className="col-md-4 col-sm-12 col-xs-12 bg-white ">
								<ThisMonthDoughnuts user={this.props.userInfo} />
							</div>
							<div className="clearfix"></div>
						</div>
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-md-4 col-sm-12 col-xs-12">
						{/* Businesses top 5 amount pledged */}
						<MyPledgesToOrganizations />
					</div>
					<div className="col-md-4 col-sm-12 col-xs-12">
						{/* Organizations top 5 pledges received */}
						<MyTopPurchasingIndividuals />
					</div>
					<div className="col-md-4 col-sm-12 col-xs-12">
						{/* Individuals top 5 amount purchased */}
						<TopReceivingOrganizations />
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<MonthlyReports />
					</div>
				</div>
			</div>
		);
	}
}

export default Dashboard;
