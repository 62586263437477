import React, { Component } from 'react';
import { Form, Input, Icon, Button, notification, Tooltip, Upload, message } from 'antd';
import { Link } from 'react-router-dom';
import { getCurrentUser, updateOrg, updateAvatar } from '../../util/APIUtils';
import { Helmet } from 'react-helmet';
import StatsLists from './StatsLists';

// const { TextArea } = Input;

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
}

export default class ProfileEdit extends Component {
	render() {
		const AntWrappedProfileForm = Form.create()(ProfileForm);
		return (
			<div className="right_col" role="main">
				<Helmet>
					<title>Organization</title>
				</Helmet>
				<StatsLists />
				<div className="row">
					<div className="col-md-12 col-sm-12 ">
						<div className="dashboard_graph">
							<div className="row x_title">
								<div className="col-md-6">
									<h3>Profile</h3>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12"></div>
							<div className="col-md-12 col-sm-12">
								<div className="x_panel">
									<div className="x_title">
										<Link to="/profile" className="btn btn-warning">
											Back
										</Link>
										<div className="clearfix"></div>
									</div>
									<div className="x_content">
										<AntWrappedProfileForm />
									</div>
								</div>
							</div>
							<div className="clearfix"></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class ProfileForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			email: '',
			name: '',
			orgName: '',
			description: '',
			goal: '',
			phone: '',
			address: '',
			bankName: '',
			bankAccountHolder: '',
			bankNumber: '',
			whatwedo: '',
			summary: '',
			loading: false,
			refId: 0,
			avatar: '',
			upload: null,
			fileList: [],
			website: ''
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleUpload = ({ fileList }) => {
		this.setState({ fileList });
		getBase64(fileList[0].originFileObj, (imageUrl) => {
			this.setState({
				avatar: imageUrl,
				loading: false,
				upload: 'iya',
			});
		});
	}

	handleChange = (info) => {
		console.log(info);
		// if (info.file.status === 'uploading') {
		//   this.setState({ loading: true });
		//   return;
		// }
		// if (info.file.status === 'done') {
		//   // Get this url from response in real world.
		getBase64(info.file.originFileObj, (imageUrl) => {
			this.setState({
				avatar: imageUrl,
				loading: false,
				upload: 'iya',
			});
		});
		// }
	};

	componentDidMount = () => {
		getCurrentUser()
			.then((response) => {
				// console.log(response)
				let image = '';
				if (response.organization.avatar === null) {
					image = '';
				} else {
					image = response.organization.avatar.url;
				}
				this.setState({
					username: response.username,
					email: response.email,
					name: response.organization.name,
					orgName: response.organization.organizationName,
					description: response.organization.description,
					goal: response.organization.goal,
					phone: response.organization.phone,
					address: response.organization.address,
					bankName: response.organization.bankName,
					bankAccountHolder: response.organization.bankAccountHolder,
					bankNumber: response.organization.bankNumber,
					whatwedo: response.organization.whatwedo,
					summary: response.organization.summary,
					avatar: image,
					refId: response.organization.id,
					website: response.organization.website
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	handleSubmit(event) {
		event.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				// do update
				const updateRequest = Object.assign({}, values);
				this.setState({loading: true})
				updateOrg(updateRequest)
					.then((response) => {
						const { upload, fileList, refId } = this.state;
						if (upload) {
							let formData = new FormData();
							formData.append('files', fileList[0].originFileObj);
							formData.append('ref', 'organization'); // optional, you need it if you want to link the image to an entry
							formData.append('refId', refId); // optional, you need it if you want to link the image to an entry
							formData.append('field', 'avatar'); // optional, you need it if you want to link the image to an entry
							console.log({ formData });
							updateAvatar(formData)
								.then((request) => {
									this.setState({loading: false})
									notification.success({
										message: 'Organization',
										description: 'Update Success',
									});
								})
								.catch((err) => {
									console.log(err);
								});
						} else {
							notification.success({
								message: 'Organization',
								description: 'Update Success',
							});
						}
					})
					.catch((error) => {
						notification.error({
							message: 'Organization',
							description: 'Sorry! Something went wrong. Please try again!',
						});
					});
			}
		});
	}

	onChange = (value) => {
		this.setState({
			inputValue: value,
		});
	};

	render() {
		// const { username, email, name, orgName, description, address, bankName, bankAccountHolder, bankNumber, phone, summary, goal, whatwedo, loading, website } = this.state;

		const {
			username,
			email,
			orgName,
			description,
			address,
			bankName,
			bankAccountHolder,
			bankNumber,
			phone,
			goal,
			loading,
			website
		} = this.state;

		const { getFieldDecorator } = this.props.form;
		const formItemLayout = {
			labelCol: {
				xs: { span: 16 },
				sm: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 16 },
				sm: { span: 8 } /*  */,
			},
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 16,
					offset: 0,
				},
				sm: {
					span: 16,
					offset: 8,
				},
			},
		};
		const uploadButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		const { avatar } = this.state;
		return (
			<Form {...formItemLayout} onSubmit={this.handleSubmit}>
				<Form.Item label="Avatar">
					{getFieldDecorator('upfile', {
						rules: [{ required: false, message: 'Please input your avatar!' }],
					})(
						<Upload
							listType="picture-card"
							className="avatar-uploader"
							showUploadList={false}
							action=''
							beforeUpload={beforeUpload}
							onChange={this.handleUpload}
						>
							{avatar ? <img src={avatar} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
						</Upload>
					)}
				</Form.Item>
				<Form.Item label="Username">
					{getFieldDecorator('username', {
						rules: [{ required: true, message: 'Please input your username!' }],
						initialValue: username,
					})(<Input disabled />)}
				</Form.Item>
				<Form.Item label="E-mail">
					{getFieldDecorator('email', {
						rules: [
							{
								type: 'email',
								message: 'The input is not valid E-mail!',
							},
							{
								required: true,
								message: 'Please input your E-mail!',
							},
						],
						initialValue: email,
					})(<Input disabled />)}
				</Form.Item>

				{/* <Form.Item
					label={
						<span>
							Name&nbsp;
							<Tooltip title="What do you want others to call you?">
								<Icon type="question-circle-o" />
							</Tooltip>
						</span>
					}
				>
					{getFieldDecorator('name', {
						rules: [{ required: true, message: 'Please input your name!', whitespace: true }],
						initialValue: name,
					})(<Input />)}
				</Form.Item> */}

				<Form.Item
					label={
						<span>
							Organization Name&nbsp;
							<Tooltip title="What do you want others to call your organization?">
								<Icon type="question-circle-o" />
							</Tooltip>
						</span>
					}
				>
					{getFieldDecorator('organizationName', {
						rules: [{ required: true, message: 'Please input your organization name!', whitespace: true }],
						initialValue: orgName,
					})(<Input />)}
				</Form.Item>

				<Form.Item label="Description">
					{getFieldDecorator('description', {
						rules: [{ required: true, message: 'Please input your description!' }],
						initialValue: description,
					})(<Input />)}
				</Form.Item>

				<Form.Item label="Website">
					{getFieldDecorator('website', {
						rules: [{ required: true, message: 'Please input your website!' }],
						initialValue: website,
					})(<Input />)}
				</Form.Item>

				<Form.Item label="Fundraising Goal">
					{getFieldDecorator('goal', {
						rules: [{ required: true, message: 'Please input your fundraising goal!' }],
						initialValue: goal,
					})(<Input />)}
				</Form.Item>

				<Form.Item label="Phone Number">
					{getFieldDecorator('phone', {
						rules: [{ required: true, message: 'Please input your phone number!' }],
						initialValue: phone,
					})(<Input />)}
				</Form.Item>

				<Form.Item label="Address">
					{getFieldDecorator('address', {
						rules: [{ required: true, message: 'Please input your address!' }],
						initialValue: address,
					})(<Input />)}
				</Form.Item>

				<Form.Item label="Bank Name">
					{getFieldDecorator('bankName', {
						rules: [{ required: true, message: 'Please input your bank name!' }],
						initialValue: bankName,
					})(<Input />)}
				</Form.Item>

				<Form.Item label="Bank Account Holder">
					{getFieldDecorator('bankAccountHolder', {
						rules: [{ required: true, message: 'Please input your bank account holder!' }],
						initialValue: bankAccountHolder,
					})(<Input />)}
				</Form.Item>

				<Form.Item label="Account Number">
					{getFieldDecorator('bankNumber', {
						rules: [{ required: true, message: 'Please input your account number!' }],
						initialValue: bankNumber,
					})(<Input />)}
				</Form.Item>

				{/* <Form.Item label="What We Do">
					{getFieldDecorator('whatwedo', {
						rules: [{ required: true, message: 'Please input your What We Do!' }],
						initialValue: whatwedo,
					})(
						<TextArea
							placeholder="Autosize height with minimum and maximum number of lines"
							autoSize={{ minRows: 2, maxRows: 6 }}
						/>
					)}
				</Form.Item> */}

				{/* <Form.Item label="About Us">
					{getFieldDecorator('summary', {
						rules: [{ required: true, message: 'Please input your about us!' }],
						initialValue: summary,
					})(
						<TextArea
							placeholder="Autosize height with minimum and maximum number of lines"
							autoSize={{ minRows: 2, maxRows: 6 }}
						/>
					)}
				</Form.Item> */}

				<Form.Item {...tailFormItemLayout}>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update Profile
					</Button>
				</Form.Item>
			</Form>
		);
	}
}
