import React, { Component } from 'react';
import List from './List'

class PlatformCoordinator extends Component {
    constructor(props){
        super(props)
        this.state = {
            isActive: false,
        }
    }
    handleActive = (e) => {
        // console.log(this.props.loc)
        if(this.props.loc === e){
            return true
        }else{
            return false
        }
    }
	render() {
		return (
			<div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
				<div className="menu_section">
					<h3>General</h3>
					<ul className="nav side-menu">
						{/* <List isActive={this.handleActive("/settings")} icon="fa fa-edit" link="/settings" name="Settings" /> */}
                        <List isActive={this.handleActive("/")} icon="fa fa-home" link="/" name="Home" />
                        <List isActive={this.handleActive("/finances")} icon="fa fa-bar-chart-o" link="/finances" name="Finances" />
                        <List isActive={this.handleActive("/transactions")} icon="fa fa-line-chart" link="/transactions" name="Transactions" />
                        <List isActive={this.handleActive("/reports")} icon="fa fa-table" link="/reports" name="Reports" />
                        <List isActive={this.handleActive("/users")} icon="fa fa-users" link="/users" name="Users List" />
                        {/* <List isActive={this.handleActive("/confirmations")} icon="fa fa-check" link="/confirmations" name="Users Confirmation" /> */}
                    </ul>
				</div>
			</div>
		);
	}
}

export default PlatformCoordinator;
