import React, { Component } from 'react';

class Footer extends Component {
	render() {
		return (
			<footer>
				<div className="pull-right">
					Gentelella - Bootstrap Admin Template by
					<a href="https://colorlib.com">Colorlib</a>
				</div>
				<div className="clearfix"></div>
			</footer>
		);
	}
}

export default Footer