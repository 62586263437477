import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { getPledgesByBusinesses } from '../../../../util/APIUtils';
import { colorHex } from '../../../../util/Helper';

export default class BussinessesTopAmountPledged extends Component {
	constructor(props) {
		super(props);
		this.state = { data: {} };
	}
	componentDidMount = () => {
		const label = [], data = [], backgroundColor = [];
		getPledgesByBusinesses()
            .then((response) => {
                // console.log(response);
                response.map((item, key) => {
                    label.push(item.business.business.businessName);
                    data.push(item.pledged);
					backgroundColor.push(colorHex[key]);
					return null;
				})
				var topLabels = label.sort((a,b) => b-a).slice(0,5);
				var topValues = data.sort((a,b) => b-a).slice(0,5);
                this.setState({
                    data: {
                        labels: topLabels,
                        datasets: [
                            {
                                backgroundColor: backgroundColor,
                                data: topValues,
                            },
                        ],
                    },
                })
            })
            .catch((err) => {
			})
	};
	render() {
		return (
			<div className="x_panel">
				<div className="x_title">
					<h4>Businesses Top 5<br />Amount Pledged</h4>
					<div className="clearfix"></div>
				</div>
				<div className="x_content">
					<Doughnut
						data={this.state.data}
						options={{
							responsive: true,
							legend: {
								display: true,
								position: 'top'
							},
						}}
						height={200}
					/>
					<div className="text-right pt-2">
						<Link to="/reports">View Monthly Report</Link>
					</div>
				</div>
			</div>
		);
	}
}
