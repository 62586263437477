import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';

import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import { Button, Icon, Skeleton } from 'antd';

import { getTransactionSummary } from '../../../util/APIUtils';
import { formatDate, colorHex } from '../../../util/Helper';

class ThisMonthDoughnuts extends Component {
	constructor(props) {
		super(props);
		this.state = { data: null, exports: {} };
	}
	componentDidMount = () => {
		const date = new Date();
		const start = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
		const end = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
		const label = [],
			data = [],
			backgroundColor = [],
			datas = [];
		getTransactionSummary(start, end)
			.then((response) => {
				response.map((item, key) => {
					backgroundColor.push(colorHex[key]);
					if(item.type === "SPENT"){
						label.push("Received");
						data.push(item.amount);
						datas.push({
							data: item.amount,
							label: "Received",
						});
					}
					if(item.type === "PLEDGE"){
						label.push("Pledged");
						data.push(item.amount);
						datas.push({
							data: item.amount,
							label: "Pledged",
						});
					}
					return null;
				});
				this.setState({
					data: {
						labels: label,
						datasets: [
							{
								backgroundColor: backgroundColor,
								data: data,
							},
						],
					},
					exports: datas,
				});
			})
			.catch((err) => {});
	};

	saveAsExcel = async () => {
		const wb = new ExcelJS.Workbook();
		// create new sheet with pageSetup settings for A4 - landscape
		const ws = wb.addWorksheet('sheet', {
			pageSetup: { paperSize: 9, orientation: 'landscape' },
		});

		let label = [],
			data = [];

		let { exports } = this.state;

		// adjust pageSetup settings afterwards
		ws.pageSetup.margins = {
			left: 0.7,
			right: 0.7,
			top: 0.75,
			bottom: 0.75,
			header: 0.3,
			footer: 0.3,
		};

		console.log(exports);

		exports.map((item, key) => {
			label.push(item.label);
			data.push(item.data);
			return null;
		});

		const title = ws.addRow(label);
		title.font = { bold: true };

		ws.addRow(data);
		const date = new Date();
		const dateNow = [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)];
		const nameFile = 'ThisMonthDougnut-' + dateNow + '.xlsx';
		const buf = await wb.xlsx.writeBuffer();
		saveAs(new Blob([buf]), nameFile);
	};

	renderBar = () => {
		const { data } = this.state;
		if (data === null) {
			return (
				<Skeleton active />
			);
		} else {
			if(data.labels.length === 0){
				return (
					<h3>This month is empty</h3>
				)
			}else{
				return (
					<Doughnut
						options={{
							responsive: true,
						}}
						height={250}
						data={this.state.data}
					/>
				);
			}
		}
	};

	render() {
		return (
			<>
				<div className="row">
					<div className="col-lg-6">
						<h4>This Month</h4>
					</div>
					<div className="col-lg-6 col-xs-12 text-right">
						<Button type="primary" onClick={() => this.saveAsExcel()} block>
							<Icon type="save" /> Export
						</Button>
					</div>
				</div>
				<div className="x_title">
					<div className="clearfix"></div>
				</div>
				<div className="col-md-12 col-sm-12">
					{this.renderBar()}
				</div>
			</>
		);
	}
}

export default ThisMonthDoughnuts;
