import React, { Component } from 'react';
import { Tabs, Skeleton, Button, Icon } from 'antd';
import MonthlyReportDetail from './MonthlyReportDetail';
import { getPledgesByOrganization, getSpentOnMe, getWhoIPledged } from '../../../util/APIUtils';
import { monthArray } from '../../../util/Helper';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
const { TabPane } = Tabs;

class MonthlyReports extends Component {
	constructor(props) {
		super(props);
		this.state = { dataPledged: [], dataSpent: [], dataOrg: [] };
	}
	componentDidMount = () => {
		getWhoIPledged(5)
			.then((response) => {
				const data = [];
				response.map((item, key) => {
					data.push({
						name: item.to.organization.organizationName,
						amount: item.pledgedTotal,
						create: new Date(item.to.created_at).getMonth() + 1,
					});
					return null;
				});
				this.setState({
					dataPledged: data,
				});
				// console.log(data)
			})
			.catch((err) => {});
		getSpentOnMe(5)
			.then((response) => {
				const data = [];
				if(response.length === 0){
					data.push({
						name: 'DataIsempty',
					});
				}else{
					response.map((item, key) => {
						data.push({
							name: item.from.individual.name,
							amount: item.spentTotal,
							create: new Date(item.from.created_at).getMonth() + 1,
						});
						return null;
					});
				}
				this.setState({
					dataSpent: data,
				});
			})
			.catch((err) => {});
		getPledgesByOrganization()
			.then((response) => {
				const data = [];
				response.map((item, key) => {
					let names, creates;
					if (Object.keys(item.organization).length === 0) {
						names = 'Data is deleted';
						creates = 13
					} else {
						names = item.organization.organization.organizationName;
						creates = new Date(item.organization.organization.created_at).getMonth() + 1
					}
					data.push({
						name: names,
						amount: item.pledgeReceived,
						create: creates,
					});
					return null;
				});
				this.setState({
					dataOrg: data,
				});
			})
			.catch((err) => {});
	};
	renderTablePledged(m) {
		const { dataPledged } = this.state;
		if (dataPledged.length === 0) {
			return <Skeleton active />;
		} else {
			let total = 0;
			let data = this.state.dataPledged;
			let dataFilter = data.filter((data) => data.create === m);
			const topValues = dataFilter.sort((a, b) => b - a).slice(0, 5);
			const dataTable = topValues.map((item, key) => {
				const { name, amount } = item; //destructuring
				total += amount;
				return (
					<tr key={key}>
						{/* <td>{key + 1}</td> */}
						<td>{name}</td>
						<td>{amount.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
					</tr>
				);
			});
			const value = total.toLocaleString(undefined, { minimumFractionDigits: 0 });
			return (
				<table className="table">
					<thead>
						<tr>
							{/* <th>#</th> */}
							<th>Name</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						{dataTable}
						<tr>
							{/* <td></td> */}
							<td>Total:</td>
							<td>{value}</td>
						</tr>
					</tbody>
				</table>
			);
		}
	}
	renderTableSpent(m) {
		const { dataSpent } = this.state;
		if (dataSpent.length === 0) {
			return <Skeleton active />;
		} else {
			let total = 0;
			let data = this.state.dataSpent;
			let dataFilter = data.filter((data) => data.create === m);
			const topValues = dataFilter.sort((a, b) => b - a).slice(0, 5);
			let dataTable
			if(this.state.dataSpent[0].name === "DataIsempty"){
				// console.log("empty")
				dataTable = () => {
					return (
						<tr>
							<td>Data is empty</td>
							<td></td>
						</tr>
					);
				}
			}else{
				dataTable = topValues.map((item, key) => {
					const { name, amount } = item; //destructuring
					total += amount;
					return (
						<tr key={key}>
							<td>{name}</td>
							<td>{amount.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
						</tr>
					);
				});
			}
			const value = total.toLocaleString(undefined, { minimumFractionDigits: 0 });
			return (
				<table className="table">
					<thead>
						<tr>
							{/* <th>#</th> */}
							<th>Name</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						{dataTable}
						<tr>
							{/* <td></td> */}
							<td>Total:</td>
							<td>{value}</td>
						</tr>
					</tbody>
				</table>
			);
		}
	}
	renderTableOrg(m) {
		const { dataOrg } = this.state;
		if (dataOrg.length === 0) {
			return <Skeleton active />;
		} else {
			let total = 0;
			let data = this.state.dataOrg;
			let dataFilter = data.filter((data) => data.create === m);
			const topValues = dataFilter.sort((a, b) => b - a).slice(0, 5);
			const dataTable = topValues.map((item, key) => {
				const { name, amount } = item; //destructuring
				total += amount;
				return (
					<tr key={key}>
						{/* <td>{key + 1}</td> */}
						<td>{name}</td>
						<td>{amount.toLocaleString(undefined, { minimumFractionDigits: 0 })}</td>
					</tr>
				);
			});
			const value = total.toLocaleString(undefined, { minimumFractionDigits: 0 });
			return (
				<table className="table">
					<thead>
						<tr>
							{/* <th>#</th> */}
							<th>Name</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						{dataTable}
						<tr>
							{/* <td></td> */}
							<td>Total:</td>
							<td>{value}</td>
						</tr>
					</tbody>
				</table>
			);
		}
	}
	renderTabPane() {
		return monthArray.map((item, key) => {
			return (
				<TabPane tab={item} key={key + 1}>
					<MonthlyReportDetail
						dataBiz={this.renderTablePledged(key + 1)}
						biz={this.state.dataPledged}
						mbiz={key + 1}
						dataOrg={this.renderTableSpent(key + 1)}
						org={this.state.dataSpent}
						morg={key + 1}
						dataInd={this.renderTableOrg(key + 1)}
						ind={this.state.dataOrg}
						mind={key + 1}
					/>
				</TabPane>
			);
		});
	}
	saveAsExcel = async () => {
		const wb = new ExcelJS.Workbook();
		// create new sheet with pageSetup settings for A4 - landscape
		const ws = wb.addWorksheet('sheet', {
			pageSetup: { paperSize: 9, orientation: 'landscape' },
		});

		let dataP = [],
			dataS = [],
			dataO = [],
			row = 1,
			labelBiz = ['Name', 'Amount', 'Month'],
			labelIndividual = ['Name', 'Amount', 'Month'],
			labelOrg = ['Name', 'Amount', 'Month'];

		let { dataPledged, dataSpent, dataOrg } = this.state;

		// adjust pageSetup settings afterwards
		ws.pageSetup.margins = {
			left: 0.7,
			right: 0.7,
			top: 0.75,
			bottom: 0.75,
			header: 0.3,
			footer: 0.3,
		};

		dataPledged.map((item, key) => {
			dataP.push([item.name, item.amount, item.create]);
			return null;
		});

		dataSpent.map((item, key) => {
			dataS.push([item.name, item.amount, item.create]);
			return null;
		});

		dataOrg.map((item, key) => {
			dataO.push([item.name, item.amount, item.create]);
			return null;
		});

		const titleBiz = ws.addRow(['My Pledges to Organizations-' + new Date().getFullYear()]);
		ws.mergeCells('A' + row + ':C' + row);
		row += 1;
		titleBiz.font = { bold: true };
		titleBiz.alignment = { vertical: 'middle', horizontal: 'center' };

		const title = ws.addRow(labelBiz);
		title.font = { bold: true };
		row += 1;

		dataP.map((item, key) => {
			ws.addRow(item);
			row += 1;
			return null;
		});

		ws.addRow();
		row += 1;
		const titleIndividual = ws.addRow(['My Top Purchasing Individuals-' + new Date().getFullYear()]);
		ws.mergeCells('A' + row + ':C' + row);
		row += 1;
		titleIndividual.font = { bold: true };
		titleIndividual.alignment = { vertical: 'middle', horizontal: 'center' };

		const title2 = ws.addRow(labelIndividual);
		title2.font = { bold: true };
		row += 1;

		dataS.map((item, key) => {
			ws.addRow(item);
			row += 1;
			return null;
		});

		ws.addRow();
		row += 1;
		const titleOrg = ws.addRow(['Top Receiving Organizations-' + new Date().getFullYear()]);
		ws.mergeCells('A' + row + ':C' + row);
		row += 1;
		titleOrg.font = { bold: true };
		titleOrg.alignment = { vertical: 'middle', horizontal: 'center' };

		const title3 = ws.addRow(labelOrg);
		title3.font = { bold: true };

		dataO.map((item, key) => {
			ws.addRow(item);
			return null;
		});
		const nameFile = 'MonthlyReport-' + new Date().getFullYear() + '.xlsx';
		const buf = await wb.xlsx.writeBuffer();
		saveAs(new Blob([buf]), nameFile);
	};
	render() {
		return (
			<div className="x_panel">
				<div className="row">
					<div className="col-lg-6 col-xs-12">
						<h4>Monthly Reports</h4>
					</div>
					<div className="col-lg-6 col-xs-12 text-right">
						<div className="row">
							<div className="col-lg-8 col-xs-12">
								{/* <MonthPicker format={dateFormat} onChange={this.onChange()} /> */}
							</div>
							<div className="col-lg-4 col-xs-12">
								<Button type="primary" onClick={() => this.saveAsExcel()} block>
									<Icon type="save" /> Export
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="x_title">
					<div className="clearfix"></div>
				</div>
				<div className="x_content">
					<Tabs defaultActiveKey="1">{this.renderTabPane()}</Tabs>
				</div>
			</div>
		);
	}
}

export default MonthlyReports;
