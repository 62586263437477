import React, { Component } from 'react'

import StatsLists from './StatsLists';
import FinancesByOrganization from './financeComponents/FinancesByOrganization';
// import FinancesAllTransactions from './financeComponents/FinancesAllTransactions';

export default class Finances extends Component {
    render() {
        return (
            <div className="right_col" role="main">
				<StatsLists />
				<div className="row">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<div className="x_panel">
							<h3>Finances - By Organization</h3>
							<div className="x_title">
								<div className="clearfix"></div>
							</div>
							<FinancesByOrganization />
                            {/* <FinancesAllTransactions /> */}
						</div>
					</div>
				</div>
			</div>
        )
    }
}
