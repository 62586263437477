import React, { Component } from 'react';
import { Modal, Button, Form, notification, Input, Icon } from 'antd';

import { pledgeOrg } from '../../util/APIUtils';

const FormItem = Form.Item;

export default class PledgeComponent extends Component {
	constructor(props) {
		super(props);
		this.state = { visible: false };
	}

	handleOk = (e) => {
		console.log(e);
		this.setState({
			visible: false,
		});
	};

	handleCancel = (e) => {
		console.log(e);
		this.setState({
			visible: false,
		});
	};
	render() {
		const { name, image, doing, goal, email, phone, address, detail, id, summary } = this.props;
		const AntWrappedLoginForm = Form.create()(PledgeForm);
		return (
			<div>
				<div className="center img-pledge">
					<img className="rounded w-img" src={image} alt="" />
				</div>
				<span className="text-center section" style={{ color: '#313380' }}>
					<b>{name}</b>
				</span>
				<p className="text-center section">{summary}</p>
				<p className="text-center section">{doing}</p>
				<p className="text-center section">Amount we are raising: {goal}</p>
				<div className="desc-center">
					<div className="row">
						<div className="col-lg-12">
							<label>
								<Icon type="mail" style={{ fontSize: '16px', color: '#313380' }}></Icon> Email :{' '}
								<span>{email}</span>
							</label>
							<br />
							<label>
								<Icon type="phone" style={{ fontSize: '16px', color: '#313380' }}></Icon> Phone :{' '}
								<span>{phone}</span>
							</label>
							<br />
							<label>
								<Icon type="environment" style={{ fontSize: '16px', color: '#313380' }}></Icon> Address
								: <span>{address}</span>
							</label>
							<br />
							<label>
								<Icon type="info-circle" style={{ fontSize: '16px', color: '#313380' }}></Icon> Details
								: <span>{detail}</span>
							</label>
							<br />
							<div className="text-center mt-2">
								<button type="submit" className="btn btn-primary " onClick={this.props.onShowPledgeInfo}>
									Pledge
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group text-center mt-4"></div>
				<Modal
					title="Pledge"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" onClick={this.handleCancel}>
							Cancel
						</Button>,
					]}
				>
					<AntWrappedLoginForm currid={id} handleOk={this.handleOk} />
				</Modal>
			</div>
		);
	}
}

class PledgeForm extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = { currid: this.props.currid, loading: false };
	}
	handleSubmit(event) {
		event.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({ loading: true });
				const pledgeRequest = Object.assign({}, values);
				// console.log(pledgeRequest);
				pledgeOrg(pledgeRequest)
					.then((response) => {
						notification.success({
							message: 'Business',
							description: 'Congratulation, you just pledge on Organization!',
						});
						this.props.handleOk();
						this.setState({ loading: false });
					})
					.catch((error) => {
						if (error.statusCode === 400) {
							notification.error({
								message: 'Business',
								description: 'Sorry! Something went wrong.',
							});
						} else {
							notification.error({
								message: 'Business',
								description: 'Sorry! Something went wrong. Please try again!',
							});
						}
					});
			}
		});
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		const { currid, loading } = this.state;
		return (
			<Form onSubmit={this.handleSubmit} className="login-form">
				<FormItem>
					{getFieldDecorator('organization_id', {
						rules: [{ required: false, message: 'Please input your amount to pledge this organization' }],
						initialValue: currid,
					})(<Input type="hidden" name="organization_id" />)}
				</FormItem>
				<FormItem>
					{getFieldDecorator('amount', {
						rules: [{ required: true, message: 'Please input your amount to pledge this organization' }],
					})(
						<Input prefix={<Icon type="wallet" />} size="large" name="amount" placeholder="Amount Pledge" />
					)}
				</FormItem>
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						size="large"
						className="login-form-button"
						loading={loading}
					>
						Pledge
					</Button>
				</FormItem>
			</Form>
		);
	}
}
