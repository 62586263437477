import React, { Component } from 'react'
import FinanceOrganization from './FinanceOrganization'
import { getListPledges } from '../../../util/APIUtils';

class FinancesByOrganization extends Component {
    constructor(props){
        super(props)
        this.state = { data: { datas: [] } };
    }
    componentDidMount() {
        const data = [];
        getListPledges()
			.then((response) => {
				response.map((item, key) => {
					data.push({
						id: item.id,
						name: item.organization.organizationName +" - "+ new Date().getFullYear(),
					});
					return null;
				});
				this.setState({
					data: {
						datas: data,
					},
				});
			})
			.catch((err) => {});
    }
    renderOrg = () => {
        // console.log(this.state.data)
		const dataOrg = this.state.data.datas.map((item, key) => {
			const { id, name } = item; //destructuring
			return (
				<FinanceOrganization name={name} id={id} key={key}/>
			);
		});
		return dataOrg;
	}
    render() {
        return this.renderOrg()
    }
}

export default FinancesByOrganization