import React, { Component } from 'react';
import { Tabs, Button, Icon, Modal, notification, Form, Input, Skeleton } from 'antd';
import FinanceDetail from './FinanceDetail';
import { recordWithdraw, getFinance, getServerCompatibility, getFiat } from '../../../util/APIUtils';
import { formatDate, monthArray } from '../../../util/Helper';

const { TabPane } = Tabs;
const FormItem = Form.Item;

export default class FinanceOrganization extends Component {
	constructor(props) {
		super(props);
		this.state = { id: this.props.id, dataFinance: [], visible: false };
	}
	componentDidMount() {
		const { id } = this.state;
		const date = new Date();
		const start = formatDate(new Date(date.getFullYear(), 0, 1));
		const end = formatDate(new Date(date.getFullYear(), 12, 0));
		getFinance(start, end, id)
			.then((response) => {
				const data = [];
				response.map((item, key) => {
					let tied = 0;
					if (item.tied !== null) {
						tied = item.tied.amount;
					} else {
						tied = 0;
					}
					data.push({
						date: item.created_at,
						transaction: item.type,
						from: item.from.username,
						to: item.to.username,
						token: item.amount,
						cash: tied,
						create: new Date(item.created_at).getMonth() + 1,
					});
					return null;
				});
				this.setState({
					dataFinance: data,
				});
			})
			.catch((err) => {});
	}
	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleOk = (e) => {
		console.log(e);
		this.setState({
			visible: false,
		});
	};

	handleCancel = (e) => {
		console.log(e);
		this.setState({
			visible: false,
		});
	};
	renderTable = (m) => {
		let totalToken = 0,
			totalCash = 0;
		let data = this.state.dataFinance;
		let dataFilter = data.filter((data) => data.create === m);
		const dataTable = dataFilter.map((item, key) => {
			const { date, transaction, from, to, token, cash } = item; //destructuring
			totalToken += token;
			totalCash += cash;
			return (
				<tr key={key}>
					<td>{date}</td>
					<td>{transaction}</td>
					<td>{from}</td>
					<td>{to}</td>
					<td>{token}</td>
					<td>{cash}</td>
				</tr>
			);
		});
		const value = totalToken.toLocaleString(undefined, { minimumFractionDigits: 0 });
		const value1 = totalCash.toLocaleString(undefined, { minimumFractionDigits: 0 });
		return (
			<>
				{dataTable}
				<tr>
					<td colSpan="4">Totals:</td>
					<td>{value}</td>
					<td>{value1}</td>
				</tr>
			</>
		);
	};
	renderTabPane() {
		return monthArray.map((item, key) => {
			return (
				<TabPane tab={item} key={key + 1}>
					<FinanceDetail data={this.renderTable(key + 1)} />
				</TabPane>
			);
		});
	}
	render() {
		const { name } = this.props;
		const { id } = this.state;
		const AntWrappedLoginForm = Form.create()(WithdrawForm);
		return (
			<div className="x_content">
				<div className="row">
					<div className="col-lg-10">
						<h3>{name}</h3>
					</div>
					<div className="col-lg-2 col-xs-12">
						<Button type="primary" onClick={this.showModal} block>
							<Icon type="wallet" /> Withdraw
						</Button>
					</div>
				</div>
				<Modal
					title={'Withdraw on ' + name}
					visible={this.state.visible}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" onClick={this.handleCancel}>
							Cancel
						</Button>,
					]}
				>
					<AntWrappedLoginForm currid={id} currname={name} handleOk={this.handleOk} />
				</Modal>
				<Tabs defaultActiveKey="1">{this.renderTabPane()}</Tabs>
			</div>
		);
	}
}

class WithdrawForm extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			currid: this.props.currid,
			currname: this.props.currname,
			feePercent: null,
			fiat: null,
			loading: false,
		};
	}
	componentDidMount() {
		getServerCompatibility()
			.then((response) => {
				this.setState({
					feePercent: response.feePercent,
				});
			})
			.catch((err) => {});

		getFiat(this.state.currid)
			.then((response) => {
				this.setState({
					fiat: response.fiat,
				});
			})
			.catch((err) => {});
	}
	handleSubmit(event) {
		event.preventDefault();
		const { currname } = this.state;
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({ loading: true });
				const pledgeRequest = Object.assign({}, values);
				recordWithdraw(pledgeRequest)
					.then((response) => {
						notification.success({
							message: 'Platform-Coordinator',
							description: 'Congratulation, withdraw on ' + currname,
						});
						this.props.handleOk();
						this.setState({ loading: false });
					})
					.catch((error) => {
						if (error.statusCode === 400) {
							notification.error({
								message: 'Platform-Coordinator',
								description: error.message,
							});
						} else {
							notification.error({
								message: 'Platform-Coordinator',
								description: 'Sorry! Something went wrong. Please try again!',
							});
						}
					});
			}
		});
	}
	renderItem = () => {
		const { currid, fiat, feePercent, loading } = this.state;
		if(fiat === null){
			return <Skeleton active/>
		}else{
			const { getFieldDecorator } = this.props.form;
			
			const fiatFee = fiat * feePercent;
			const fiatAmount = fiat - fiatFee;
			return (
				<Form onSubmit={this.handleSubmit} className="login-form">
				<FormItem>
					{getFieldDecorator('organization_id', {
						rules: [{ required: false, message: 'Please input your amount to pledge this organization' }],
						initialValue: currid,
					})(<Input type="hidden" name="organization_id" />)}
				</FormItem>
				<FormItem label="Fiat Balance">
					<Input
						prefix={<Icon type="wallet" />}
						size="large"
						placeholder="Fiat Balance"
						value={fiat}
						disabled
					/>
				</FormItem>
				<FormItem label="Fiat Amount">
					{getFieldDecorator('fiatamount', {
						rules: [
							{ required: true, message: 'Please input your fiat amount to withdraw this organization' },
						],
						initialValue: fiatAmount,
					})(
						<Input
							prefix={<Icon type="wallet" />}
							size="large"
							name="fiatamount"
							placeholder="Fiat Amount"
						/>
					)}
				</FormItem>
				<FormItem label="Fiat Fee">
					{getFieldDecorator('fiatfee', {
						rules: [
							{ required: true, message: 'Please input your fiat fee to withdraw this organization' },
						],
						initialValue: fiatFee,
					})(<Input prefix={<Icon type="wallet" />} size="large" name="fiatfee" placeholder="Fiat Fee" />)}
				</FormItem>
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						size="large"
						className="login-form-button"
						loading={loading}
					>
						Withdraw
					</Button>
				</FormItem>
			</Form>
			)
		}
	}
	render() {
		return this.renderItem();
	}
}
