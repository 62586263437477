import React, { Component } from 'react'
import {
	LoginPage,
	ForgotPage,
	PlatformDashboard,
	PlatformFinances,
	PlatformReports,
	PlatformUsers,
	BusinessDashboard,
	BusinessProfile,
	BusinessProfileEdit,
	BusinessPledge,
	OrganizationDasboard,
	OrganizationProfile,
	OrganizationProfileEdit,
	PlatformTransaction
} from '../pages';
import { getCurrentUser } from '../util/APIUtils';
import { notification } from 'antd';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUser, clearUser } from '../actions';
import { Loading } from '../common';
import { Sidebar, Topnav, Footer } from '../components';
import { Context } from '../context';

class App extends Component {
	constructor(props) {
		super(props);
		this.onChangeSidebar = () => {
			this.setState((prevState) => ({
				sidebarType: prevState.sidebarType === 'nav-md' ? 'nav-sm' : 'nav-md',
			}));
		};
		this.state = {
			sidebarType: 'nav-md',
			onChangeSidebar: this.onChangeSidebar,
			userStatus: '',
			userInfo: '',
			isAuthenticated: false,
			isLoading: false,
		};
		notification.config({
			placement: 'topRight',
			top: 70,
			duration: 3,
		});
	}
	componentDidMount() {
		getCurrentUser()
			.then((response) => {
				this.setState({
					userStatus: response.role.type,
					userInfo: response
				});
				this.props.setUser(response);
				this.props.history.push('/');
			})
			.catch((error) => {
				this.props.history.push('/login');
				this.props.clearUser();
			});
	}

	loadCurrentUser = () => {
		getCurrentUser()
			.then((response) => {
				this.setState({
					userStatus: response.role.type,
					userInfo: response
				});
			})
			.catch((error) => {
				this.setState({
					isLoading: false,
				});
			});
	};

	handleLogin = (user) => {
		const role = user.user.role.type
		if(role === "cordinator"){
			notification.success({
				message: 'Platform Coordinator',
				description: "you're successfully logged in.",
			});
		}else if(role === "business"){
			notification.success({
				message: 'Business',
				description: "you're successfully logged in.",
			});
		}else if(role === "organization"){
			notification.success({
				message: 'Organization',
				description: "you're successfully logged in.",
			});
		}
	
		setTimeout((props) => {
			window.location.href = '/';
			this.loadCurrentUser();
		}, 3000)
	};

	render() {
		if (this.props.isLoading) {
			return <Loading />;
		}
		if (this.state.userStatus === 'business') {
			return (
				<Context.Provider value={this.state}>
					<div className={this.state.sidebarType}>
						<div className="container body">
							<div className="main_container">
								<Sidebar location={this.props.history} userStatus={this.state.userStatus} userInfo={this.state.userInfo} />
								<Topnav userInfo={this.state.userInfo} />
								<Switch>
									<Route exact path="/" render={(props) => <BusinessDashboard userInfo={this.state.userInfo}/>} />
									<Route path="/profile" component={BusinessProfile} />
									<Route path="/edit-profile" component={BusinessProfileEdit} />
									<Route path="/pledge" component={BusinessPledge} />
									<Footer />
								</Switch>
							</div>
						</div>
					</div>
				</Context.Provider>
			);
		} else if (this.state.userStatus === 'cordinator') {
			return (
				<Context.Provider value={this.state}>
					<div className={this.state.sidebarType}>
						<div className="container body">
							<div className="main_container">
								<Sidebar location={this.props.history} userStatus={this.state.userStatus} userInfo={this.state.userInfo} />
								<Topnav userInfo={this.state.userInfo} />
								<Switch>
									<Route exact path="/" component={PlatformDashboard} />
									{/* <Route path="/settings" component={PlatformSetting} /> */}
									{/* <Route path="/edit-settings" component={PlatformSettingEdit} /> */}
									<Route path="/finances" component={PlatformFinances} />
									<Route path="/reports" component={PlatformReports} />
									{/* <Route path="/confirmations" component={PlatformConfirmations} /> */}
									<Route path="/users" component={PlatformUsers} />
									<Route path="/transactions" component={PlatformTransaction} />
									<Footer />
								</Switch>
							</div>
						</div>
					</div>
				</Context.Provider>
			);
		} else if (this.state.userStatus === 'organization') {
			return (
				<Context.Provider value={this.state}>
					<div className={this.state.sidebarType}>
						<div className="container body">
							<div className="main_container">
								<Sidebar location={this.props.history} userStatus={this.state.userStatus} userInfo={this.state.userInfo} />
								<Topnav userInfo={this.state.userInfo} />
								<Switch>
									<Route exact path="/" render={(props) => <OrganizationDasboard userInfo={this.state.userInfo}/>} />
									<Route path="/profile" component={OrganizationProfile} />
									<Route path="/edit-profile" component={OrganizationProfileEdit} />
									<Footer />
								</Switch>
							</div>
						</div>
					</div>
				</Context.Provider>
			);
		} else {
			return (
				<Switch>
					{/* <Route exact path="/" component={App} /> */}
					<Route path="/login" render={(props) => <LoginPage onLogin={this.handleLogin} {...props} />} />
					<Route path="/forgot" render={(props) => <ForgotPage onLogin={this.handleLogin} {...props} />} />
				</Switch>
			);
		}
	}
}

const mapStateFromProps = (state) => ({
	isLoading: state.user.isLoading,
});

const RootWithAuth = withRouter(connect(mapStateFromProps, { setUser, clearUser })(App));

export default RootWithAuth