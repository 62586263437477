import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import {
	getAllBusinesses,
	getAllOrganizations,
	unblockAccount,
	blockAccount,
	unverifyAccount,
	verifyAccount
} from '../../util/APIUtils';
import Highlighter from 'react-highlight-words';
import { Table, Icon, Input, Button, Modal, notification, Tag} from 'antd';

const { confirm } = Modal;

export default class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchText: '',
			searchedColumn: '',
			dataOrg: [],
			dataBiz: [],
		};
	}
	
	componentDidMount() {
		getAllBusinesses()
			.then((response) => {
				let dataConfirm = [];
				response.map((item, key) => {
					if (item !== null) {
						let data_cache = {
							id: key + 1,
							key: item.id,
							email: item.email,
							role: item.role.type,
							blocked: item.blocked,
							confirm: item.confirmed,
							verified: item.business.verified,
						};
						dataConfirm.push(data_cache);
					}
					return null;
				});
				this.setState({
					dataBiz: dataConfirm,
				});
			})
			.catch((err) => {
				console.log(err);
			});
		getAllOrganizations()
			.then((response) => {
				console.log(response)
				let dataConfirm = [];
				response.map((item, key) => {
					if (item !== null) {
						let data_cache = {
							id: key + 1,
							key: item.id,
							email: item.email,
							role: item.role.type,
							blocked: item.blocked,
							confirm: item.confirmed,
							verified: item.organization.verified,
						};
						dataConfirm.push(data_cache);
					}
					return null;
				});
				this.setState({
					dataOrg: dataConfirm,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}
    
	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon="search"
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
					Search
				</Button>
				<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: (text) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
				text
			),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	confirmOrg = (id) => {
		confirm({
			title: 'Do you want to confirm these organization?',
			content: 'When clicked the OK button, this dialog will be closed after 1 second',
			onOk() {
				return new Promise((resolve, reject) => {
					const verifyRequest = { organization_id: id };
					console.log(verifyRequest)
					verifyAccount(verifyRequest)
						.then((response) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Organization successfully confirmed',
							});
							
						})
						.catch((err) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Organization successfully confirmed',
							});
							// console.log(err);
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
				}).catch(() => console.log());
			},
			onCancel() {},
		});
	};

	confirmBiz = (id) => {
		confirm({
			title: 'Do you want to confirm these business?',
			content: 'When clicked the OK button, this dialog will be closed after 1 second',
			onOk() {
				return new Promise((resolve, reject) => {
					const verifyRequest = { business_id: id };
					console.log(verifyRequest)
					verifyAccount(verifyRequest)
						.then((response) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Business successfully confirmed',
							});
						})
						.catch((err) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Business successfully confirmed',
							});
							// console.log(err);
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
				}).catch(() => console.log());
			},
			onCancel() {},
		});
	};

	unverifyBiz = (id) => {
		confirm({
			title: 'Do you want to unverify these account?',
			content: 'When clicked the OK button, this dialog will be closed after 1 second',
			onOk() {
				return new Promise((resolve, reject) => {
					const verifyRequest = { business_id: id };
					console.log(verifyRequest);
					unverifyAccount(verifyRequest)
						.then((response) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Account successfully unverify',
							});
						})
						.catch((err) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Account successfully unverify',
							});
							// console.log(err);
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
				}).catch(() => console.log());
			},
			onCancel() {},
		});
	};

	unverifyOrg = (id) => {
		confirm({
			title: 'Do you want to unverify these account?',
			content: 'When clicked the OK button, this dialog will be closed after 1 second',
			onOk() {
				return new Promise((resolve, reject) => {
					const verifyRequest = { organization_id: id };
					console.log(verifyRequest);
					unverifyAccount(verifyRequest)
						.then((response) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Account successfully unverify',
							});
						})
						.catch((err) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Account successfully unverify',
							});
							// console.log(err);
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
				}).catch(() => console.log());
			},
			onCancel() {},
		});
	};

	unblockUser = (id) => {
		confirm({
			title: 'Do you want to unblock these account?',
			content: 'When clicked the OK button, this dialog will be closed after 1 second',
			onOk() {
				return new Promise((resolve, reject) => {
					const verifyRequest = { user_id: id };
					console.log(verifyRequest);
					unblockAccount(verifyRequest)
						.then((response) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Account successfully unblock',
							});
						})
						.catch((err) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Account successfully unblock',
							});
							// console.log(err);
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
				}).catch(() => console.log());
			},
			onCancel() {},
		});
	};

	blockUser = (id) => {
		confirm({
			title: 'Do you want to block these account?',
			content: 'When clicked the OK button, this dialog will be closed after 1 second',
			onOk() {
				return new Promise((resolve, reject) => {
					const verifyRequest = { user_id: id };
					console.log(verifyRequest);
					blockAccount(verifyRequest)
						.then((response) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Account successfully blocked',
							});
						})
						.catch((err) => {
							notification.success({
								message: 'Platform-Coordinator',
								description: 'Account successfully blocked',
							});
							// console.log(err);
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
				}).catch(() => console.log());
			},
			onCancel() {},
		});
	};

	render() {
		const columnsOrg = [
			{
				title: 'ID',
				dataIndex: 'key',
				key: 'key',
				fixed: 'left',
			},
			{
				title: 'Email',
				dataIndex: 'email',
				key: 'email',
				width: '30%',
				...this.getColumnSearchProps('email'),
			},
			{
				title: 'Blocked',
				dataIndex: 'blocked',
				key: 'blocked',
				render: (text, record) => {
					if(record.blocked){
						return(
							<Tag color="red">Yes</Tag>
						)
					}else{
						return(
							<Tag color="green">No</Tag>
						)
					}
				}
			},
			{
				title: 'Confirmed',
				dataIndex: 'confirm',
				key: 'confirm',
				render: (text, record) => {
					if(record.confirm){
						return(
							<Tag color="green">Yes</Tag>
						)
					}else{
						return(
							<Tag color="red">No</Tag>
						)
					}
				}
			},
			{
				title: 'Verified',
				dataIndex: 'verified',
				key: 'verified',
				render: (text, record) => {
					if(record.verified){
						return(
							<Tag color="green">Yes</Tag>
						)
					}else{
						return(
							<Tag color="red">No</Tag>
						)
					}
				}
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
				width: '30%',
				render: (text, record) => {
					const confirmation = () => {
						if(record.verified){
							return (
								<Button className="ml-2" onClick={() => this.unverifyOrg(record.key)}>
									Unverify
								</Button>
							)
						}else{
							return (
								<Button className="ml-2" type="primary" onClick={() => this.confirmOrg(record.key)}>Verify</Button>
							)
						}
					}

					const blockeds = () => {
						if(record.blocked){
							return (
								<Button className="ml-2" onClick={() => this.unblockUser(record.key)}>
									Unblock
								</Button>
							)
						}else{
							return (
								<Button className="ml-2" type="danger" onClick={() => this.blockUser(record.key)}>
									Block
								</Button>
							)
						}
					} 

					return (
						<span>
							{confirmation()}
							{blockeds()}
						</span>
					);
				}
			},
		];
		const columnsBiz = [
			{
				title: 'ID',
				dataIndex: 'key',
				key: 'key',
				fixed: 'left',
			},
			{
				title: 'Email',
				dataIndex: 'email',
				key: 'email',
				width: '30%',
				...this.getColumnSearchProps('email'),
			},
			{
				title: 'Blocked',
				dataIndex: 'blocked',
				key: 'blocked',
				render: (text, record) => {
					if(record.blocked){
						return(
							<Tag color="red">Yes</Tag>
						)
					}else{
						return(
							<Tag color="green">No</Tag>
						)
					}
				}
			},
			{
				title: 'Confirmed',
				dataIndex: 'confirm',
				key: 'confirm',
				render: (text, record) => {
					if(record.confirm){
						return(
							<Tag color="green">Yes</Tag>
						)
					}else{
						return(
							<Tag color="red">No</Tag>
						)
					}
				}
			},
			{
				title: 'Verified',
				dataIndex: 'verified',
				key: 'verified',
				render: (text, record) => {
					if(record.verified){
						return(
							<Tag color="green">Yes</Tag>
						)
					}else{
						return(
							<Tag color="red">No</Tag>
						)
					}
				}
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
				width: '30%',
				render: (text, record) => {
					const confirmation = () => {
						if(record.verified){
							return (
								<Button className="ml-2" onClick={() => this.unverifyBiz(record.key)}>
									Unverify
								</Button>
							)
						}else{
							return (
								<Button className="ml-2" type="primary" onClick={() => this.confirmBiz(record.key)}>Verify</Button>
							)
						}
					}

					const blockeds = () => {
						if(record.blocked){
							return (
								<Button className="ml-2" onClick={() => this.unblockUser(record.key)}>
									Unblock
								</Button>
							)
						}else{
							return (
								<Button className="ml-2" type="danger" onClick={() => this.blockUser(record.key)}>
									Block
								</Button>
							)
						}
					} 

					return (
						<span>
							{confirmation()}
							{blockeds()}
						</span>
					);
				},
			},
		];
		const { dataOrg, dataBiz } = this.state;
		return (
			<div className="right_col" role="main">
				<div>
					<div className="page-title">
						<div className="title_left">
							<h4>Users List</h4>
						</div>
					</div>
					<div className="clearfix"></div>
					<div className="x_panel">
						<h4>Organization List</h4>
						<div className="x_title">
							<div className="clearfix"></div>
						</div>
						<div className="x_content">
							<Table columns={columnsOrg} dataSource={dataOrg} scroll={{ x: 500 }} />
						</div>
						<h4>Business List</h4>
						<div className="x_title">
							<div className="clearfix"></div>
						</div>
						<div className="x_content">
							<Table columns={columnsBiz} dataSource={dataBiz} scroll={{ x: 500 }} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
