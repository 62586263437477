import React from 'react';
import {getRelativeTime} from '../util/Helper';

export default function RecentNotification(props) {
	const { title, body, sender, createdAt } = props
	return (
		<li>
			<div className="block">
				<div className="block_content">
					<h2 className="title">{title}</h2> {body}
					<div className="byline">
						<span>{getRelativeTime(createdAt)}</span> By - {sender}
					</div>
				</div>
			</div>
		</li>
	);
}
