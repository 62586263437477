import React, { Component } from 'react'
import './Login.css';
import { Link } from 'react-router-dom';
import { login } from '../../../util/APIUtils';
import { Form, Input, Icon, Button, Card, notification} from 'antd';
// import { getCurrentUser } from '../../../util/APIUtils';
import { ACCESS_TOKEN } from '../../../constants';

const FormItem = Form.Item;

class Login extends Component {
    render() {
        const AntWrappedLoginForm = Form.create()(LoginForm)
        return (
            <div className="login-container">
                <div className="login-body">
                    <img src="Logo.png" alt="Logo" style={{  height: '128px' }} />
                    <h3>Login to Platform</h3>
                    <Card bordered={false} style={{ width: 300 }}>
                        <div className="login-content">
                            <AntWrappedLoginForm onLogin={this.props.onLogin} />
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {loading:false}
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true })
                // do login
                const loginRequest = Object.assign({}, values);
                login(loginRequest)
                    .then(response => {
                        localStorage.setItem(ACCESS_TOKEN, response.jwt);
                        this.props.onLogin(response);
                    }).catch(error => {
                        this.setState({ loading: false })
                        console.log()
                        if (error.statusCode === 400) {
                            notification.error({
                                message: 'Platform',
                                description: error.message[0].messages[0].message
                            });
                        } else {
                            notification.error({
                                message: 'Platform',
                                description: 'Sorry! Something went wrong. Please try again!'
                            });
                        }
                    });
            }
        });
    }

    render() {
        const { loading } = this.state
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit}>
                <FormItem>
                    {getFieldDecorator('identifier', {
                        rules: [{ required: true, message: 'Please input your username or email!' }],
                    })(
                        <Input
                            prefix={<Icon type="user" />}
                            size="large"
                            name="identifier"
                            placeholder="Username or Email" />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Please input your Password!' }],
                    })(
                        <Input.Password
                            prefix={<Icon type="lock" />}
                            size="large"
                            name="password"
                            type="password"
                            placeholder="Password" />
                    )}
                </FormItem>
                <FormItem>
                    
                    <Button type="primary" htmlType="submit" size="large" className="login-form-button" loading={loading}>Login</Button>
                    <Link to="/forgot">Forgot Password ? </Link>
                </FormItem>
            </Form>
        );
    }
}

export default Login