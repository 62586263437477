import React from 'react';
import { Link } from 'react-router-dom'

function ProfileList(props) {

	return (
		<ul className="dropdown-menu dropdown-usermenu pull-right">
			{/* <li>
				<Link to="/profile"> Profile</Link>
			</li>
			<li>
				<Link to="/setting">
					<span className="badge bg-red pull-right">50%</span>
					<span>Settings</span>
				</Link>
			</li>
			<li>
				<Link to="/help">Help</Link>
			</li> */}
			<li>
				<Link onClick={() => props.handleLogout('/login', 'error', 'You have been logged out. Please login to vote')}>
					<i className="fa fa-sign-out pull-right"></i> Log Out
				</Link>
			</li>
		</ul>
	);
}

export default ProfileList;
