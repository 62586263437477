import React, { Component } from 'react';
import StatsComponent from './StatsComponent';
import { getUserSummary, getUserSummaryAll, getTransactionSummary, getTransactionSummaryAll } from '../../util/APIUtils';
import { formatDate, findPercent } from '../../util/Helper';

export default class StatsLists extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allIndividual: 0,
			allBusiness: 0,
			allOrganization: 0,
			allPledge: 0,
			allTopup: 0,
			allSpent: 0,

			totalIndividual: 0,
			totalBusiness: 0,
			totalOrganization: 0,
			totalPledge: 0,
			totalTopup: 0,
			totalSpent: 0,

			percentTotalIndividual: null,
			percentTotalBusiness: null,
			percentTotalOrganization: null,
			percentTotalPledge: null,
			percentTotalTopup: null,
			percentTotalSpent: null,
		};
	}
	componentDidMount() {
		const date = new Date();
		const start = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
		const end = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

		const startLast = formatDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
		const endLast = formatDate(new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0));

		getUserSummaryAll()
			.then((response) => {
				this.setState({
					allIndividual: response.individualCount,
					allBusiness: response.businessCount,
					allOrganization: response.organizationCount,
				});
			})
			.catch((err) => {
				console.log(err);
			})

		getUserSummary(start, end)
			.then((response) => {
				this.setState({
					totalIndividual: response.individualCount,
					totalBusiness: response.businessCount,
					totalOrganization: response.organizationCount,
				});
				getUserSummary(startLast, endLast)
					.then((response) => {
						const { totalIndividual, totalBusiness, totalOrganization, allIndividual, allBusiness, allOrganization } = this.state;

						this.setState({
							percentTotalIndividual: findPercent((totalIndividual - response.individualCount), allIndividual),
							percentTotalBusiness: findPercent((totalBusiness - response.businessCount), allBusiness),
							percentTotalOrganization: findPercent((totalOrganization - response.organizationCount), allOrganization)
						});
					})
					.catch((err) => {
						console.log(err);
					});
			})
			.catch((err) => {
				console.log(err);
			});

		getTransactionSummaryAll()
			.then((response) => {
				response.map((item) => {
					if (item.type === 'PLEDGE') {
                        this.setState({ allPledge: item.amount });
					} else if (item.type === 'TOPUP') {
                        this.setState({ allTopup: item.amount });
					} else if (item.type === 'SPENT') {
                        this.setState({ allSpent: item.amount });
					}
					return null;
				});
			})
			.catch((err) => {
				console.log(err)
			})

		getTransactionSummary(start, end)
			.then((response) => {
                // console.log(response)
                const data = {totalPledge: 0, totalTopup: 0, totalSpent:0}
				response.map((item) => {
					const value = item.amount.toLocaleString(undefined, { minimumFractionDigits: 0 });
					if (item.type === 'PLEDGE') {
                        this.setState({ totalPledge: value });
                        data.totalPledge = item.amount
					} else if (item.type === 'TOPUP') {
                        this.setState({ totalTopup: value });
                        data.totalTopup = item.amount
					} else if (item.type === 'SPENT') {
                        this.setState({ totalSpent: value });
                        data.totalSpent = item.amount
					}
					return null;
				});

				getTransactionSummary(startLast, endLast)
					.then((response) => {
						console.log(response)
						const { totalPledge, totalTopup, totalSpent } = data;
						if(response.length === 0){
							this.setState({ percentTotalPledge: findPercent(100, 100) });
							this.setState({ percentTotalTopup: findPercent(100, 100) });
							this.setState({ percentTotalSpent: findPercent(100, 100) });
						}else{
							const { allPledge, allTopup, allSpent } = this.state;
							response.map((item) => {
								if (item.type === 'PLEDGE') {
									this.setState({ percentTotalPledge: findPercent((totalPledge - item.amount), allPledge) });
								}else{
									this.setState({ percentTotalPledge: findPercent((totalPledge - 0), allPledge) });
								}
								if (item.type === 'TOPUP') {
									this.setState({ percentTotalTopup: findPercent((totalTopup - item.amount), allTopup) });
								}else{
									this.setState({ percentTotalTopup: findPercent((totalTopup - 0), allTopup) });
								}
								if (item.type === 'SPENT') {
									this.setState({ percentTotalSpent: findPercent((totalSpent - item.amount), allSpent) });
								}else{
									this.setState({ percentTotalSpent: findPercent((totalSpent - 0), allSpent) });
								}
								return null;
							});
						}
					})
					.catch((err) => {
						console.log(err);
					});
			})
			.catch((err) => {
				console.log(err);
			});
	}
	render() {
		const {
			allIndividual,
			allBusiness,
			allOrganization,
			allPledge,
			allTopup,
			allSpent,
			totalIndividual,
			totalBusiness,
			totalOrganization,
			totalPledge,
			totalTopup,
			totalSpent,
			percentTotalBusiness,
			percentTotalIndividual,
			percentTotalOrganization,
			percentTotalPledge,
			percentTotalSpent,
			percentTotalTopup,
		} = this.state;
		return (
			<div className="row tile_count">
				<StatsComponent
					icon="fa fa-user"
					title="Total Individuals"
					data={totalIndividual}
					all={allIndividual}
					percentage={percentTotalIndividual}
				/>
				<StatsComponent
					icon="fa fa-user"
					title="Total Businesses"
					data={totalBusiness}
					all={allBusiness}
					percentage={percentTotalBusiness}
				/>
				<StatsComponent
					icon="fa fa-user"
					title="Total Organizations"
					data={totalOrganization}
					all={allOrganization}
					percentage={percentTotalOrganization}
				/>
				<StatsComponent
					icon="fa fa-money"
					title="Total Pledged"
					data={totalPledge}
					all={allPledge.toLocaleString(undefined, { minimumFractionDigits: 0 })}
					percentage={percentTotalPledge}
				/>
				<StatsComponent
					icon="fa fa-money"
					title="Total Topup"
					data={totalTopup}
					all={allTopup.toLocaleString(undefined, { minimumFractionDigits: 0 })}
					percentage={percentTotalTopup}
				/>
				<StatsComponent
					icon="fa fa-money"
					title="Total Spent"
					data={totalSpent}
					all={allSpent.toLocaleString(undefined, { minimumFractionDigits: 0 })}
					percentage={percentTotalSpent}
				/>
			</div>
		);
	}
}
